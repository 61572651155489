import { CircularProgress } from "@mui/material";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import AgentUserDetailPageLayout from "./AgentUserDetailPageLayout";
import ErrorPage from "../ErrorPage/ErrorPage";

/**
 * 代理店ユーザーの詳細ページ
 */
const AgentUserDetailPage = () => (
  <ErrorBoundary fallback={<ErrorPage />}>
    <Suspense fallback={<CircularProgress />}>
      <AgentUserDetailPageLayout />
    </Suspense>
  </ErrorBoundary>
);

export default AgentUserDetailPage;
