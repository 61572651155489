/**
 * ルーティングのURLの設定
 */
export const homePagePath = "/";
export const loginPagePath = "/login";
export const resetPasswordPath = "/reset-password";
export const passwordSettingPath = "/password";
export const agentUserRegisterPagePath = "/agent-user-register";
export const agentUserDetailPagePath = "/agent-user-detail";
export const agentUserUpdatePagePath = "/agent-user-update";
export const agentUserListPagePath = "/agent-user-list";
export const schoolRegisterPagePath = "/school-register";
export const schoolUpdatePagePath = "/school-update";
export const schoolListPagePath = "/school-list";
export const liveRegisterPagePath = "/live-register";
export const liveUpdatePagePath = "/live-update";
export const liveDetailPagePath = "/live-detail";
export const liveListPagePath = "/live-list";
export const archiveRegisterPagePath = "/archive-register";
export const archiveUpdatePagePath = "/archive-update";
export const archiveListPagePath = "/archive-list";
export const archiveDetailPagePath = "/archive-detail";
export const errorPagePath = "/error";
export const notFoundPagePath = "*";
