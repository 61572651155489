import {
  Container,
  Stack,
  Box,
  Typography,
  Avatar,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect } from "react";
import useSchoolRegisterPageState from "./useSchoolRegisterPageState";
import InputForm from "../../components/InputForm/InputForm";
import PageBase from "../PageBase/PageBase";
import BackButton from "../../components/BackButton/BackButton";
import TiTleText from "../../components/TiTleText/TiTleText";

/**
 * 学校情報の登録ページ
 */
const SchoolRegisterPage = () => {
  const {
    state,
    isLoading,
    onChangeName,
    onChangeCode,
    onChangeDescription,
    onClickUploadFile,
    onClickRegister,
    getRandomText,
  } = useSchoolRegisterPageState();

  // 初回はランダムな7桁なコードを取得
  useEffect(() => {
    getRandomText();
  }, [getRandomText]);

  return (
    <PageBase row={12}>
      <Container maxWidth="xs">
        <Stack
          paddingY={6}
          direction="column"
          justifyContent="center"
          spacing={4}
        >
          <BackButton />
          <TiTleText
            title="学校の登録"
            subTitle={`学校情報の登録を行います。\n各項目を入力してください。`}
          />
          <Box />
          <Stack
            direction="row"
            justifyContent="start"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              sx={{
                bgcolor: "grey",
                width: 156,
                height: 156,
                margin: "auto",
              }}
              alt={state.name ?? "Thumnail"}
              src={
                state.uploadFile != null
                  ? window.URL.createObjectURL(state.uploadFile)
                  : ""
              }
            />
            <Box>
              <Button variant="outlined" component="label">
                サムネイル画像の
                <br />
                アップロード
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={onClickUploadFile}
                />
              </Button>
            </Box>
          </Stack>
          <InputForm
            label="学校名"
            isLabelRequired
            maxLines={1}
            value={state.name}
            onChange={onChangeName}
            error={state.errors?.name}
          />
          <Stack
            direction="row"
            justifyContent="start"
            alignItems="end"
            spacing={2}
          >
            <InputForm
              label="コード"
              isLabelRequired
              maxLines={1}
              value={state.code}
              onChange={onChangeCode}
              error={state.errors?.code}
            />
            <Box>
              <LoadingButton
                variant="outlined"
                loading={isLoading}
                sx={{ borderRadius: "8px", fontSize: "12px" }}
                onClick={getRandomText}
              >
                ランダムな７桁取得
              </LoadingButton>
            </Box>
          </Stack>
          <InputForm
            label="備考"
            multiline
            minLines={3}
            value={state.description}
            onChange={onChangeDescription}
          />
          <Box />
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              borderRadius: "4px",
              elevation: 3,
              py: "16px",
              fontSize: 16,
            }}
            loadingIndicator="読込中..."
            loading={isLoading}
            disabled={isLoading}
            onClick={onClickRegister}
          >
            <Typography color="#ffffff">この内容で登録する</Typography>
          </LoadingButton>
        </Stack>
      </Container>
    </PageBase>
  );
};

export default SchoolRegisterPage;
