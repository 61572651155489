import { atom } from "recoil";

export type AuthGuardState = {
  /** ユーザID */
  userId: string;
  /** ユーザタイプ */
  userType: "" | "admin" | "agent";
};

const initialState: AuthGuardState = {
  userId: "",
  userType: "",
};

const authGuardStateAtom = atom({
  key: "AuthGuardState",
  default: initialState,
});

export default authGuardStateAtom;
