import { useRecoilState, useResetRecoilState } from "recoil";
import { useCallback, useEffect } from "react";
import { useMutation } from "react-query";
import { getFunctions, httpsCallable } from "firebase/functions";
import agentUserRegisterPageStateAtom from "../../recoil/AgentUserRegisterPage/atom";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { IAgentUser, validateAgentUser } from "../../interfaces/IAgentUser";
import { createAgentUser } from "../../repositories/agentUserRepository";

/**
 * 代理店ユーザー登録ページの状態管理やロジックをまとめたHooks
 */
const useAgentUserRegisterPageState = () => {
  const [state, setState] = useRecoilState(agentUserRegisterPageStateAtom);
  const resetState = useResetRecoilState(agentUserRegisterPageStateAtom);
  const { openAlert } = useResultAlertState();

  useEffect(() => {
    resetState();
  }, [resetState]);

  // InputFormの入力値の状態をonChangeで保持
  /**
   * メールアドレスの変更を状態に反映させる。
   */
  const onChangeEmail = useCallback(
    (email: string) => {
      setState((prev) => ({ ...prev, email }));
    },
    [setState]
  );
  /**
   * パスワードの変更を状態に反映させる。
   */
  const onChangePassword = useCallback(
    (password: string) => {
      setState((prev) => ({ ...prev, password }));
    },
    [setState]
  );
  /**
   * 代理店名の変更を状態に反映させる。
   */
  const onChangeName = useCallback(
    (name: string) => {
      setState((prev) => ({ ...prev, name }));
    },
    [setState]
  );

  /**
   * 代理店ユーザー情報の登録する。
   */
  const registerAgentUser = useCallback(async () => {
    const errors = validateAgentUser(state.email, state.password, state.name);
    if (errors) {
      setState((prev) => ({ ...prev, errors }));
      throw new Error("入力情報を確認してください。");
    }
    setState((prev) => ({ ...prev, errors: undefined }));

    const agentUser: IAgentUser = {
      id: "",
      name: state.name,
      isDeleted: false,
      createdAt: new Date().getTime(),
    };
    const functions = getFunctions();
    functions.region = "asia-northeast1";
    const createUserFunction = httpsCallable<unknown, string>(
      functions,
      "createUser"
    );
    const reqParam = {
      email: state.email,
      password: state.password,
    };
    const createUserResponse = await createUserFunction(reqParam);
    console.log(createUserResponse);
    console.log(createUserResponse.data);

    const uid = createUserResponse.data;
    agentUser.id = uid;
    await createAgentUser(agentUser, agentUser.id);
  }, [setState, state.name, state.email, state.password]);
  const registerMutate = useMutation<void, Error>(() => registerAgentUser(), {
    onSuccess: () => {
      openAlert("success", "代理店ユーザーの作成に成功しました。");
      resetState();
    },
    onError: (error) => {
      openAlert("error", error.message);
    },
  });

  /**
   * 登録ボタンクリック時の処理
   */
  const onClickRegister = useCallback(() => {
    void registerMutate.mutate();
  }, [registerMutate]);

  return {
    state,
    isLoading: registerMutate.isLoading,
    onChangeEmail,
    onChangePassword,
    onChangeName,
    onClickRegister,
  };
};

export default useAgentUserRegisterPageState;
