import { useRecoilState, useResetRecoilState } from "recoil";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { schoolListPagePath } from "../../layout/urls";
import {
  deleteSchool,
  getSchoolById,
  onSnapshotAllSchools,
  updateSchool,
} from "../../repositories/schoolRepository";
import schoolUpdatePageStateAtom from "../../recoil/SchoolUpdatePage/atom";
import {
  ISchool,
  SchoolErrors,
  validateSchool,
} from "../../interfaces/ISchool";
import { validateSchoolCode } from "../../utils/validator";
import { ramdomText } from "../../utils/utilities";
import {
  getThumnailSrc,
  uploadThumnail,
} from "../../repositories/storageRepository";

/**
 * 学校情報の更新ページの状態管理やロジックをまとめたHooks
 */
const useSchoolUpdatePageState = (targetSchoolId: string) => {
  const [state, setState] = useRecoilState(schoolUpdatePageStateAtom);
  const resetState = useResetRecoilState(schoolUpdatePageStateAtom);
  const { openAlert } = useResultAlertState();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [schoolCodeList, setSchoolCodeList] = useState<string[]>([]);
  const [schoolList, setSchoolList] = useState<ISchool[]>([]);

  const { data: initialSchool } = useQuery(
    ["getSchoolById", targetSchoolId],
    () => getSchoolById(targetSchoolId ?? "")
  );

  useEffect(() => {
    resetState();
    if (initialSchool) {
      setState((prev) => ({
        ...prev,
        name: initialSchool.name,
        code: initialSchool.code,
        description: initialSchool.description,
        thumbnailUrl: initialSchool.thumbnailUrl,
      }));
    }
  }, [initialSchool, setState, resetState]);

  /**
   * 学校コード一覧のリアルタイム取得
   */
  useEffect(() => {
    const sub = onSnapshotAllSchools((snapshot) => {
      if (snapshot.docs.length > 0) {
        const res = snapshot.docs.map((value) => value.data());
        const codeList = res.map((x) => x.code);
        setSchoolCodeList(codeList);
        setSchoolList(res);
      } else {
        setSchoolCodeList([]);
        setSchoolList([]);
      }
    });
    return () => {
      sub();
    };
  }, []);

  /**
   * 学校コードのランダム発行
   */
  const getRandomText = () => {
    let flag = true;
    while (flag) {
      const code = ramdomText(7);
      const undefinedOrString = validateSchoolCode(code, schoolCodeList);
      // undefinedの場合、使用可能なコードとしてセット
      if (undefinedOrString === undefined) {
        setState((prev) => ({ ...prev, code }));
        flag = false;
      }
    }
  };

  // InputFormの入力値の状態をonChangeで保持
  /**
   * 学校名の変更を状態に反映させる。
   */
  const onChangeName = useCallback(
    (name: string) => {
      setState((prev) => ({ ...prev, name }));
    },
    [setState]
  );
  /**
   * コードの変更を状態に反映させる。
   */
  const onChangeCode = useCallback(
    (code: string) => {
      const errors: SchoolErrors = {
        name: state.errors?.name,
        code: validateSchoolCode(code, schoolCodeList),
      };
      setState((prev) => ({ ...prev, code, errors }));
    },
    [schoolCodeList, setState, state.errors?.name]
  );
  /**
   * その他備考欄の内容を状態に反映させる。
   */
  const onChangeDescription = useCallback(
    (description: string) => {
      setState((prev) => ({ ...prev, description }));
    },
    [setState]
  );
  /**
   * サムネイル画像のアップロードを状態に反映させる。
   */
  const onClickUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0] ?? null;
    setState((prev) => ({ ...prev, uploadFile: file }));
  };

  /**
   * 学校情報のリフレッシュトリガー
   */
  const onRefresh = () => {
    resetState();
    void queryClient.resetQueries(["getSchoolById", targetSchoolId]);
  };

  /**
   * 学校情報の更新する。
   */
  const updateSchoolInfo = useCallback(async () => {
    if (!initialSchool || !initialSchool.id) {
      throw new Error("学校情報の更新に失敗しました。");
    }
    let errors = validateSchool(state.name, state.code);
    if (errors) {
      setState((prev) => ({ ...prev, errors }));
      throw new Error("入力情報を確認してください。");
    }
    const isNotAvailableCodeStr = validateSchoolCode(
      state.code,
      schoolCodeList
    );
    const isSelfCode =
      schoolList.filter(
        (e) => e.id === initialSchool.id && e.code === state.code
      ).length > 0;
    if (isNotAvailableCodeStr && !isSelfCode) {
      errors = {
        name: undefined,
        code: isNotAvailableCodeStr,
      };
      setState((prev) => ({ ...prev, errors }));
      throw new Error("入力情報を確認してください。");
    }
    setState((prev) => ({ ...prev, errors: undefined }));

    let thumbnailImageUrl = initialSchool.thumbnailUrl;
    if (state.uploadFile != null) {
      const thumnailPath =
        (await uploadThumnail("schools", initialSchool.id, state.uploadFile)) ??
        "";
      thumbnailImageUrl = (await getThumnailSrc(thumnailPath)) ?? "";
    }
    const school: ISchool = {
      id: initialSchool.id,
      name: state.name,
      code: state.code,
      thumbnailUrl: thumbnailImageUrl,
      description: state.description,
      agentUserId: initialSchool.agentUserId,
      subscriptionItemId: initialSchool.subscriptionItemId,
      isDeleted: false,
      createdAt: initialSchool.createdAt,
    };
    await updateSchool(initialSchool.id, school);
  }, [
    initialSchool,
    state.name,
    state.code,
    state.uploadFile,
    state.description,
    schoolCodeList,
    schoolList,
    setState,
  ]);
  const updateMutate = useMutation<void, Error>(() => updateSchoolInfo(), {
    onSuccess: () => {
      openAlert("success", "学校情報の更新に成功しました。");
      onRefresh();
    },
    onError: (error) => {
      openAlert("error", error.message);
    },
  });

  /**
   * 更新ボタンクリック時の処理
   */
  const onClickUpdate = useCallback(() => {
    void updateMutate.mutate();
  }, [updateMutate]);

  /**
   * 学校情報の削除する。
   */
  const deleteSchoolInfo = useCallback(async () => {
    if (!initialSchool || !initialSchool.id) {
      throw new Error("学校情報の削除に失敗しました。");
    }
    await deleteSchool(initialSchool.id);
  }, [initialSchool]);
  const deleteMutate = useMutation<void, Error>(() => deleteSchoolInfo(), {
    onSuccess: () => {
      openAlert("success", "学校情報の削除に成功しました。");
      navigate(schoolListPagePath);
    },
    onError: (error) => {
      openAlert("error", error.message);
    },
  });

  /**
   * 削除ボタンクリック時の処理
   */
  const onClickDelete = useCallback(() => {
    void deleteMutate.mutate();
  }, [deleteMutate]);

  return {
    state,
    isLoading: updateMutate.isLoading || deleteMutate.isLoading,
    onChangeName,
    onChangeCode,
    onChangeDescription,
    onClickUploadFile,
    onClickUpdate,
    onClickDelete,
    getRandomText,
  };
};

export default useSchoolUpdatePageState;
