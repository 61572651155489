import { atom } from "recoil";
import { ArchiveErrors } from "../../interfaces/IArchive";

export type ArchiveRegisterPageState = {
  /** アーカイブタイトル */
  title: string;
  /** 学校ID */
  schoolId: string;
  /** 備考欄 */
  description: string;
  /** サムネイル画像ファイル */
  uploadFile: File | null;
  /** アーカイブ動画ファイル */
  archiveMovieFile: File | null;
  /** バリデーションエラー */
  errors: ArchiveErrors | undefined;
};

const initialState: ArchiveRegisterPageState = {
  title: "",
  schoolId: "",
  description: "",
  uploadFile: null,
  archiveMovieFile: null,
  errors: undefined,
};

const archiveRegisterPageStateAtom = atom({
  key: "ArchiveRegisterPageState",
  default: initialState,
});

export default archiveRegisterPageStateAtom;
