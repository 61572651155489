import { CircularProgress } from "@mui/material";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import LiveUpdatePageLayout from "./LiveUpdatePageLayout";
import ErrorPage from "../ErrorPage/ErrorPage";

/**
 * ライブ情報の更新ページ
 */
const LiveUpdatePage = () => (
  <ErrorBoundary fallback={<ErrorPage />}>
    <Suspense fallback={<CircularProgress />}>
      <LiveUpdatePageLayout />
    </Suspense>
  </ErrorBoundary>
);

export default LiveUpdatePage;
