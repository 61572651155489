import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { validateRequired } from "../utils/validator";

/**
 * アーカイブ情報を表現するインターフェース
 */
export interface IArchive {
  id?: string;
  liveId: string;
  title: string;
  description: string;
  thumbnailUrl: string;
  schoolId: string;
  archiveMovieUrl: string;
  createdAt: number;
  createdUser: string;
}

/**
 * アーカイブ情報のバリデーション。
 */
export type ArchiveErrors = {
  title: string | undefined;
  schoolId: string | undefined;
  archiveMovie: string | undefined;
};
export const validateArchive = (
  title: string,
  schoolId: string,
  archiveMovie: string
): ArchiveErrors | undefined => {
  const errors: ArchiveErrors = {
    title: undefined,
    schoolId: undefined,
    archiveMovie: undefined,
  };
  errors.title = validateRequired(title);
  errors.schoolId = validateRequired(schoolId);
  errors.archiveMovie = validateRequired(archiveMovie);
  if (
    Object.values(errors).findIndex(
      (element) => typeof element === "string"
    ) !== -1
  ) {
    return errors;
  }
  return undefined;
};

/**
 * Firestoreからの情報取得用、データコンバータ。
 * Firestoreからのsnapshotと、アーカイブ情報の型を変換する。
 */
export const archiveConverter: FirestoreDataConverter<IArchive> = {
  toFirestore: (archive: IArchive): DocumentData => ({
    liveId: archive.liveId,
    title: archive.title,
    description: archive.description,
    thumbnailUrl: archive.thumbnailUrl,
    schoolId: archive.schoolId,
    archiveMovieUrl: archive.archiveMovieUrl,
    createdAt: archive.createdAt,
    createdUser: archive.createdUser,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): IArchive => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      liveId: data.liveId as string,
      title: data.title as string,
      description: data.description as string,
      thumbnailUrl: data.thumbnailUrl as string,
      schoolId: data.schoolId as string,
      archiveMovieUrl: data.archiveMovieUrl as string,
      createdAt: data.createdAt as number,
      createdUser: data.createdUser as string,
    } as IArchive;
  },
};
