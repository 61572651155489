import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
} from "@mui/material";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { ReactNode } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import {
  agentUserListPagePath,
  agentUserUpdatePagePath,
  archiveListPagePath,
  liveListPagePath,
  passwordSettingPath,
  schoolListPagePath,
} from "../../layout/urls";
import authGuardStateAtom from "../../recoil/AuthGuard/atom";

type MenuItemType = {
  label: string;
  icon: ReactNode;
  onClick?: () => void;
};

/**
 * PC・タブレット向けナビゲーションドロワー
 */
const NavDrawer = () => {
  const drawerWidth = 280;
  const navigate = useNavigate();
  const authState = useRecoilValue(authGuardStateAtom);

  /**
   * ドロワー表示メニュー項目
   */
  const menuItems: MenuItemType[] =
    authState.userType === "agent"
      ? [
          {
            label: "配信",
            icon: <LiveTvIcon />,
            onClick: () => {
              navigate(liveListPagePath);
            },
          },
          {
            label: "動画アーカイブマスタ",
            icon: <LocalMoviesIcon />,
            onClick: () => {
              navigate(archiveListPagePath);
            },
          },
          {
            label: "学校マスタ",
            icon: <AccountBalanceIcon />,
            onClick: () => {
              navigate(schoolListPagePath);
            },
          },
        ]
      : [
          {
            label: "代理店ユーザーマスタ",
            icon: <SupportAgentIcon />,
            onClick: () => {
              navigate(agentUserListPagePath);
            },
          },
        ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {menuItems.map((item) => (
            <ListItem key={item.label}>
              <ListItemButton onClick={item.onClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    fontSize: 18,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <Stack pt={4} spacing={1} justifyContent="start" alignItems="start">
          {authState.userType === "agent" ? (
            <Button
              fullWidth
              variant="text"
              color="secondary"
              sx={{
                fontSize: 16,
              }}
              onClick={() => {
                navigate(agentUserUpdatePagePath, {
                  state: authState.userId,
                });
              }}
            >
              アカウント更新
            </Button>
          ) : null}
          <Button
            fullWidth
            variant="text"
            color="secondary"
            sx={{
              fontSize: 16,
            }}
            onClick={() => {
              navigate(passwordSettingPath);
            }}
          >
            パスワード設定
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default NavDrawer;
