import { Box, Container, Grid, Stack } from "@mui/material";
import { FC, ReactNode } from "react";
import useBreakpoint from "../../utils/useBreakPoint";
import CustomAppBar from "../../components/CustomAppBar/CustomAppBar";
import NavDrawer from "../NavDrawer/NavDrawer";

/**
 * 全般ページのベースコンポーネント
 */
const PageBase: FC<{ children?: ReactNode; row: number }> = ({
  children = null,
  row,
}) => {
  const { innerHeight, breakpoint } = useBreakpoint();

  const desktopSizeWidgets = (
    <Container maxWidth={false} style={{ padding: 0 }}>
      <Grid container alignItems="center" justifyContent="center">
        <CustomAppBar />
        <Grid item xs={0} alignItems="center" justifyContent="center">
          <NavDrawer />
        </Grid>
        <Grid item xs={row} alignItems="center" justifyContent="center">
          <Box
            bgcolor="#FFFFFF"
            minHeight="100vh"
            sx={{ mt: "80px", ml: "300px", mr: "20px" }}
          >
            {children}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );

  /// ToDo: レスポンシブ対応
  const tabletSizeWidgets = (
    <Container maxWidth={false} style={{ padding: 0 }}>
      <CustomAppBar />
      <Box bgcolor="#FFFFFF">
        <Stack
          direction="column"
          justifyContent="center"
          minHeight={innerHeight}
        >
          {children}
        </Stack>
      </Box>
    </Container>
  );

  switch (breakpoint) {
    case "xs":
    case "sm":
      return tabletSizeWidgets;
    case "md":
    case "lg":
    case "xl":
      return desktopSizeWidgets;
    default:
      return desktopSizeWidgets;
  }
};

export default PageBase;
