import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

/**
 * 戻るボタン
 */
const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div style={{ textAlign: "start" }}>
      <Button color="secondary" onClick={() => navigate(-1)}>
        <Stack
          direction="row"
          spacing={0.5}
          justifyContent="center"
          alignItems="center"
        >
          <ArrowBackIcon color="secondary" sx={{ fontSize: 32 }} />
          <Typography color="secondary" variant="subtitle1">
            戻る
          </Typography>
        </Stack>
      </Button>
    </div>
  );
};

export default BackButton;
