import { AlertColor } from "@mui/material";
import { useRecoilState } from "recoil";
import { ReactNode, useCallback } from "react";
import resultAlertStateAtom from "../../recoil/ResultAlert/atom";

/**
 * アラート表示制御
 */
const useResultAlertState = () => {
  const [state, setState] = useRecoilState(resultAlertStateAtom);

  /**
   * アラートを表示する。
   */
  const openAlert = useCallback(
    (result: AlertColor, message: ReactNode) => {
      setState((prev) => ({ ...prev, isOpen: true, result, message }));
    },
    [setState]
  );

  /**
   * アラートを閉じる。
   */
  const closeAlert = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: false }));
  }, [setState]);

  return {
    state,
    openAlert,
    closeAlert,
  };
};

export default useResultAlertState;
