import {
  Container,
  Stack,
  Typography,
  Grid,
  Button,
  Box,
  Avatar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import PageBase from "../PageBase/PageBase";
import {
  liveDetailPagePath,
  liveRegisterPagePath,
  liveUpdatePagePath,
} from "../../layout/urls";
import { onSnapshotLivesByCreatedUser } from "../../repositories/liveRepository";
import { ILive } from "../../interfaces/ILive";
import { auth } from "../../firebase";
import { date2String } from "../../utils/converter";

/**
 * ライブ一覧表示のページ
 */
const LiveListPage = () => {
  const navigate = useNavigate();
  const [liveList, setLiveList] = useState<ILive[]>([]);

  /**
   * ライブ一覧のリアルタイム取得
   */
  useEffect(() => {
    const sub = onSnapshotLivesByCreatedUser((snapshot) => {
      if (snapshot.docs.length > 0) {
        const res = snapshot.docs.map((value) => value.data());
        setLiveList(res);
      } else {
        setLiveList([]);
      }
    }, auth.currentUser?.uid ?? "");
    return () => {
      sub();
    };
  }, []);

  return (
    <PageBase row={12}>
      <Container>
        <Stack
          paddingY={6}
          direction="column"
          justifyContent="center"
          spacing={4}
        >
          <Typography
            variant="h4"
            sx={{ fontSize: { xs: "24px", sm: "30px" } }}
          >
            ライブ一覧
          </Typography>
          <Grid container justifyContent="end" alignItems="end">
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  navigate(liveRegisterPagePath);
                }}
              >
                <Typography variant="caption" sx={{ color: "#FFFFFF" }}>
                  新規追加
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} my={0}>
              {liveList.length > 0 ? (
                liveList.map((item) => (
                  <Container
                    key={item.id}
                    sx={{
                      width: "100%",
                      borderRadius: "4px",
                      borderColor: "#8C8C8C",
                      background: item.isFinished ? "#D3D3D3" : "#FFFFFF",
                      border: 1,
                      p: "8px",
                      my: "8px",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack
                        direction="row"
                        justifyContent="start"
                        alignItems="center"
                        spacing={4}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          if (item.isFinished) {
                            return;
                          }
                          navigate(liveDetailPagePath, {
                            state: item.id as string,
                          });
                        }}
                      >
                        <Box>
                          <Avatar
                            variant="rounded"
                            sx={{
                              bgcolor: "grey",
                              width: 96,
                              height: 56,
                            }}
                            alt={item.title}
                            src={item.thumbnailUrl}
                          >
                            No Image
                          </Avatar>
                        </Box>
                        <Stack
                          direction="column"
                          justifyContent="start"
                          alignItems="start"
                        >
                          <Typography
                            variant="body1"
                            sx={{ color: "#8C8C8C", textTransform: "none" }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ color: "#8C8C8C" }}
                          >
                            {date2String(new Date(item.liveStartAt))}~
                            {date2String(new Date(item.liveEndAt))}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ color: "#8C8C8C" }}
                          >
                            {item.description}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          navigate(liveUpdatePagePath, {
                            state: item.id as string,
                          });
                        }}
                      >
                        更新
                      </Button>
                    </Stack>
                  </Container>
                ))
              ) : (
                <div>ライブ情報が存在していません。</div>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </PageBase>
  );
};

export default LiveListPage;
