import { CircularProgress } from "@mui/material";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import AgentUserUpdatePageLayout from "./AgentUserUpdatePageLayout";
import ErrorPage from "../ErrorPage/ErrorPage";

/**
 * 代理店ユーザー情報の更新ページ
 */
const AgentUserUpdatePage = () => (
  <ErrorBoundary fallback={<ErrorPage />}>
    <Suspense fallback={<CircularProgress />}>
      <AgentUserUpdatePageLayout />
    </Suspense>
  </ErrorBoundary>
);

export default AgentUserUpdatePage;
