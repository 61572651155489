import "./App.css";
import { CssBaseline } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  errorPagePath,
  agentUserListPagePath,
  loginPagePath,
  passwordSettingPath,
  agentUserRegisterPagePath,
  resetPasswordPath,
  agentUserUpdatePagePath,
  schoolRegisterPagePath,
  schoolUpdatePagePath,
  schoolListPagePath,
  liveRegisterPagePath,
  liveUpdatePagePath,
  liveListPagePath,
  liveDetailPagePath,
  archiveRegisterPagePath,
  archiveUpdatePagePath,
  archiveListPagePath,
  notFoundPagePath,
  homePagePath,
  agentUserDetailPagePath,
  archiveDetailPagePath,
} from "./layout/urls";
import LoginPage from "./features/LoginPage/LoginPage";
import ResetPasswordPage from "./features/ResetPasswordPage/ResetPasswordPage";
import AgentUserRegisterPage from "./features/AgentUserRegisterPage/AgentUserRegisterPage";
import PasswordSettingPage from "./features/PasswordSettingPage/PasswordSettingPage";
import ErrorPage from "./features/ErrorPage/ErrorPage";
import AgentUserUpdatePage from "./features/AgentUserUpdatePage";
import AuthGuard from "./features/AuthGuard/AuthGuard";
import AgentUserListPage from "./features/AgentUserListPage/AgentUserListPage";
import SchoolRegisterPage from "./features/SchoolRegisterPage/SchoolRegisterPage";
import SchoolUpdatePage from "./features/SchoolUpdatePage";
import SchoolListPage from "./features/SchoolListPage/SchoolListPage";
import LiveRegisterPage from "./features/LiveRegisterPage/LiveRegisterPage";
import LiveUpdatePage from "./features/LiveUpdatePage";
import LiveListPage from "./features/LiveListPage/LiveListPage";
import LiveDetailPage from "./features/LiveDetailPage/LiveDetailPage";
import ArchiveRegisterPage from "./features/ArchiveRegisterPage/ArchiveRegisterPage";
import ArchiveUpdatePage from "./features/ArchiveUpdatePage";
import ArchiveListPage from "./features/ArchiveListPage/ArchiveListPage";
import NotFoundPage from "./features/NotFoundPage/NotFoundPage";
import AgentUserDetailPage from "./features/AgentUserDetailPage";
import ArchiveDetailPage from "./features/ArchiveDetailPage/ArchiveDetailPage";

const App = () => (
  <div className="App">
    <CssBaseline>
      <BrowserRouter>
        <Routes>
          <Route
            path={homePagePath}
            element={
              <AuthGuard isNotLoggedInOnly>
                <LoginPage />
              </AuthGuard>
            }
          />
          <Route
            path={loginPagePath}
            element={
              <AuthGuard isNotLoggedInOnly>
                <LoginPage />
              </AuthGuard>
            }
          />
          <Route path={resetPasswordPath} element={<ResetPasswordPage />} />
          <Route
            path={passwordSettingPath}
            element={
              <AuthGuard isCommon>
                <PasswordSettingPage />
              </AuthGuard>
            }
          />
          <Route
            path={agentUserRegisterPagePath}
            element={
              <AuthGuard isAdminOnly>
                <AgentUserRegisterPage />
              </AuthGuard>
            }
          />
          <Route
            path={agentUserUpdatePagePath}
            element={
              <AuthGuard isCommon>
                <AgentUserUpdatePage />
              </AuthGuard>
            }
          />
          <Route
            path={agentUserListPagePath}
            element={
              <AuthGuard isAdminOnly>
                <AgentUserListPage />
              </AuthGuard>
            }
          />
          <Route
            path={agentUserDetailPagePath}
            element={
              <AuthGuard isAdminOnly>
                <AgentUserDetailPage />
              </AuthGuard>
            }
          />
          <Route
            path={schoolRegisterPagePath}
            element={
              <AuthGuard isAgentOnly>
                <SchoolRegisterPage />
              </AuthGuard>
            }
          />
          <Route
            path={schoolUpdatePagePath}
            element={
              <AuthGuard isAgentOnly>
                <SchoolUpdatePage />
              </AuthGuard>
            }
          />
          <Route
            path={schoolListPagePath}
            element={
              <AuthGuard isAgentOnly>
                <SchoolListPage />
              </AuthGuard>
            }
          />
          <Route
            path={liveRegisterPagePath}
            element={
              <AuthGuard isAgentOnly>
                <LiveRegisterPage />
              </AuthGuard>
            }
          />
          <Route
            path={liveDetailPagePath}
            element={
              <AuthGuard isAgentOnly>
                <LiveDetailPage />
              </AuthGuard>
            }
          />
          <Route
            path={liveUpdatePagePath}
            element={
              <AuthGuard isAgentOnly>
                <LiveUpdatePage />
              </AuthGuard>
            }
          />
          <Route
            path={liveListPagePath}
            element={
              <AuthGuard isAgentOnly>
                <LiveListPage />
              </AuthGuard>
            }
          />
          <Route
            path={archiveRegisterPagePath}
            element={
              <AuthGuard isAgentOnly>
                <ArchiveRegisterPage />
              </AuthGuard>
            }
          />
          <Route
            path={archiveUpdatePagePath}
            element={
              <AuthGuard isAgentOnly>
                <ArchiveUpdatePage />
              </AuthGuard>
            }
          />
          <Route
            path={archiveListPagePath}
            element={
              <AuthGuard isAgentOnly>
                <ArchiveListPage />
              </AuthGuard>
            }
          />
          <Route
            path={archiveDetailPagePath}
            element={
              <AuthGuard isAgentOnly>
                <ArchiveDetailPage />
              </AuthGuard>
            }
          />
          <Route
            path={errorPagePath}
            element={
              <AuthGuard isNotLoggedInOnly>
                <ErrorPage />
              </AuthGuard>
            }
          />
          <Route path={notFoundPagePath} element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </CssBaseline>
  </div>
);

export default App;
