import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";

/**
 * 管理者ユーザー情報を表現するインターフェース
 */
export interface IAdminUser {
  id: string;
  name: string;
}

/**
 * Firestoreからの情報取得用、データコンバータ。
 * Firestoreからのsnapshotと、管理者ユーザー情報の型を変換する。
 */
export const adminUserConverter: FirestoreDataConverter<IAdminUser> = {
  toFirestore: (adminUser: IAdminUser): DocumentData => ({
    id: adminUser.id,
    name: adminUser.name,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): IAdminUser => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name as string,
    } as IAdminUser;
  },
};
