import { useQuery } from "react-query";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router";
import PageBase from "../PageBase/PageBase";
import { getArchiveById } from "../../repositories/archiveRepository";
import { getArchiveMovieSrc } from "../../repositories/s3Repository";

/**
 * アーカイブ詳細表示のページ
 */
const ArchiveDetailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const targetArchiveId = location.state as string;

  const { data, isLoading } = useQuery(
    ["getArchiveById", targetArchiveId],
    () => getArchiveById(targetArchiveId ?? "")
  );

  const { data: archiveMovieSrc } = useQuery(
    ["getArchiveMovieSrc", data?.archiveMovieUrl],
    () => getArchiveMovieSrc(data?.archiveMovieUrl ?? "")
  );

  return (
    <PageBase row={12}>
      <Container maxWidth="md">
        {data && !isLoading ? (
          <Stack
            direction="column"
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              mt="36px"
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              style={{ width: "100%" }}
            >
              <Typography variant="h5"> {data.title}</Typography>
              <div style={{ textAlign: "end" }}>
                <Button color="primary" onClick={() => navigate(-1)}>
                  <Stack
                    direction="row"
                    spacing={0.5}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CloseIcon sx={{ fontSize: 40 }} />
                    <Typography variant="h5">閉じる</Typography>
                  </Stack>
                </Button>
              </div>
            </Stack>
            <Box my={2} />
            <div>
              <video
                controls
                style={{
                  objectFit: "fill",
                  width: "90%",
                }}
              >
                <source src={archiveMovieSrc} type="video/mp4" />
                <track
                  src="captions_en.vtt"
                  kind="captions"
                  label="english_captions"
                />
              </video>
            </div>
            <Box my={1} />
            <Typography variant="caption">{data.description}</Typography>
          </Stack>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Container>
    </PageBase>
  );
};

export default ArchiveDetailPage;
