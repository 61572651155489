import { useRecoilState, useResetRecoilState } from "recoil";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import schoolRegisterPageStateAtom from "../../recoil/SchoolRegisterPage/atom";
import {
  ISchool,
  SchoolErrors,
  validateSchool,
} from "../../interfaces/ISchool";
import {
  createSchool,
  getSchoolById,
  onSnapshotAllSchools,
  updateSchool,
} from "../../repositories/schoolRepository";
import { validateSchoolCode } from "../../utils/validator";
import { ramdomText } from "../../utils/utilities";
import { schoolListPagePath } from "../../layout/urls";
import { auth } from "../../firebase";
import {
  getThumnailSrc,
  uploadThumnail,
} from "../../repositories/storageRepository";

/**
 * 学校情報の登録ページの状態管理やロジックをまとめたHooks
 */
const useSchoolRegisterPageState = () => {
  const navigate = useNavigate();
  const [state, setState] = useRecoilState(schoolRegisterPageStateAtom);
  const resetState = useResetRecoilState(schoolRegisterPageStateAtom);
  const { openAlert } = useResultAlertState();
  const [schoolCodeList, setSchoolCodeList] = useState<string[]>([]);
  const [subscriptionItemIdList, setSubscriptionItemIdList] = useState<
    string[]
  >([]);

  useEffect(() => {
    resetState();
  }, [resetState]);

  /**
   * 学校コード一覧のリアルタイム取得
   */
  useEffect(() => {
    const sub = onSnapshotAllSchools((snapshot) => {
      if (snapshot.docs.length > 0) {
        const res = snapshot.docs.map((value) => value.data());
        const codeList = res.map((x) => x.code);
        setSchoolCodeList(codeList);
        const subscriptionItemIds = res.map((y) => y.subscriptionItemId);
        setSubscriptionItemIdList(subscriptionItemIds);
      } else {
        setSchoolCodeList([]);
        setSubscriptionItemIdList([]);
      }
    });
    return () => {
      sub();
    };
  }, []);

  /**
   * 学校コードのランダム発行
   */
  const getRandomText = useCallback(() => {
    let flag = true;
    while (flag) {
      const code = ramdomText(7);
      const undefinedOrString = validateSchoolCode(code, schoolCodeList);
      // undefinedの場合、使用可能なコードとしてセット
      if (undefinedOrString === undefined) {
        setState((prev) => ({ ...prev, code }));
        flag = false;
      }
    }
  }, [schoolCodeList, setState]);

  // InputFormの入力値の状態をonChangeで保持
  /**
   * 学校名の変更を状態に反映させる。
   */
  const onChangeName = useCallback(
    (name: string) => {
      setState((prev) => ({ ...prev, name }));
    },
    [setState]
  );
  /**
   * コードの変更を状態に反映させる。
   */
  const onChangeCode = useCallback(
    (code: string) => {
      const errors: SchoolErrors = {
        name: state.errors?.name,
        code: validateSchoolCode(code, schoolCodeList),
      };
      setState((prev) => ({ ...prev, code, errors }));
    },
    [schoolCodeList, setState, state.errors?.name]
  );
  /**
   * その他備考欄の内容を状態に反映させる。
   */
  const onChangeDescription = useCallback(
    (description: string) => {
      setState((prev) => ({ ...prev, description }));
    },
    [setState]
  );
  /**
   * サムネイル画像のアップロードを状態に反映させる。
   */
  const onClickUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0] ?? null;
    setState((prev) => ({ ...prev, uploadFile: file }));
  };

  /**
   * 学校情報の登録する。
   */
  const registerSchool = useCallback(async () => {
    if (auth.currentUser == null) {
      return;
    }
    let errors = validateSchool(state.name, state.code);
    if (errors) {
      setState((prev) => ({ ...prev, errors }));
      throw new Error("入力情報を確認してください。");
    }
    const isNotAvailableCodeStr = validateSchoolCode(
      state.code,
      schoolCodeList
    );
    if (isNotAvailableCodeStr) {
      errors = {
        name: undefined,
        code: isNotAvailableCodeStr,
      };
      setState((prev) => ({ ...prev, errors }));
      throw new Error("入力情報を確認してください。");
    }
    setState((prev) => ({ ...prev, errors: undefined }));

    let subscriptionItemId = "";
    let flag = true;
    while (flag) {
      subscriptionItemId = ramdomText(10);
      const isNotAvailable =
        subscriptionItemIdList.includes(subscriptionItemId);
      if (!isNotAvailable) {
        flag = false;
      }
    }
    const school: ISchool = {
      id: "",
      name: state.name,
      code: state.code,
      thumbnailUrl: "",
      description: state.description,
      subscriptionItemId,
      agentUserId: auth.currentUser.uid,
      isDeleted: false,
      createdAt: new Date().getTime(),
    };
    const createdSchoolDoc = await createSchool(school);
    const createdSchool = await getSchoolById(createdSchoolDoc.id);
    if (createdSchool != null && state.uploadFile != null) {
      const schoolId = createdSchoolDoc.id;
      const thumnailPath =
        (await uploadThumnail("schools", schoolId, state.uploadFile)) ?? "";
      const thumbnailUrl = (await getThumnailSrc(thumnailPath)) ?? "";
      createdSchool.thumbnailUrl = thumbnailUrl;
      await updateSchool(schoolId, createdSchool);
    }
  }, [
    state.name,
    state.code,
    state.uploadFile,
    state.description,
    schoolCodeList,
    subscriptionItemIdList,
    setState,
  ]);
  const registerMutate = useMutation<void, Error>(() => registerSchool(), {
    onSuccess: () => {
      openAlert("success", "学校情報の作成に成功しました。");
      resetState();
      navigate(schoolListPagePath);
    },
    onError: (error) => {
      openAlert("error", error.message);
    },
  });

  /**
   * 登録ボタンクリック時の処理
   */
  const onClickRegister = useCallback(() => {
    void registerMutate.mutate();
  }, [registerMutate]);

  return {
    state,
    isLoading: registerMutate.isLoading,
    onChangeName,
    onChangeCode,
    onChangeDescription,
    onClickUploadFile,
    onClickRegister,
    getRandomText,
  };
};

export default useSchoolRegisterPageState;
