import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { validateRequired } from "../utils/validator";

/**
 * 学校情報を表現するインターフェース
 */
export interface ISchool {
  id?: string;
  name: string;
  code: string;
  thumbnailUrl: string;
  description: string;
  agentUserId: string;
  subscriptionItemId: string;
  isDeleted: boolean;
  createdAt: number;
}

/**
 * 学校情報のバリデーション。
 */
export type SchoolErrors = {
  name: string | undefined;
  code: string | undefined;
};
export const validateSchool = (
  name: string,
  code: string
): SchoolErrors | undefined => {
  const errors: SchoolErrors = {
    name: undefined,
    code: undefined,
  };
  errors.name = validateRequired(name);
  errors.code = validateRequired(code);
  if (
    Object.values(errors).findIndex(
      (element) => typeof element === "string"
    ) !== -1
  ) {
    return errors;
  }
  return undefined;
};

/**
 * Firestoreからの情報取得用、データコンバータ。
 * Firestoreからのsnapshotと、学校情報の型を変換する。
 */
export const schoolConverter: FirestoreDataConverter<ISchool> = {
  toFirestore: (school: ISchool): DocumentData => ({
    name: school.name,
    code: school.code,
    thumbnailUrl: school.thumbnailUrl,
    description: school.description,
    agentUserId: school.agentUserId,
    subscriptionItemId: school.subscriptionItemId,
    isDeleted: school.isDeleted,
    createdAt: school.createdAt,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ISchool => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name as string,
      code: data.code as string,
      thumbnailUrl: data.thumbnailUrl as string,
      description: data.description as string,
      agentUserId: data.agentUserId as string,
      subscriptionItemId: data.subscriptionItemId as string,
      isDeleted: data.isDeleted as boolean,
      createdAt: data.createdAt as number,
    } as ISchool;
  },
};
