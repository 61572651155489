import { CircularProgress } from "@mui/material";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ArchiveUpdatePageLayout from "./ArchiveUpdatePageLayout";
import ErrorPage from "../ErrorPage/ErrorPage";

/**
 * アーカイブ情報の更新ページ
 */
const ArchiveUpdatePage = () => (
  <ErrorBoundary fallback={<ErrorPage />}>
    <Suspense fallback={<CircularProgress />}>
      <ArchiveUpdatePageLayout />
    </Suspense>
  </ErrorBoundary>
);

export default ArchiveUpdatePage;
