import { useRecoilState, useResetRecoilState } from "recoil";
import { ChangeEvent, useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { archiveListPagePath } from "../../layout/urls";
import archiveUpdatePageStateAtom from "../../recoil/ArchiveUpdatePage/atom";
import {
  deleteArchive,
  getArchiveById,
  updateArchive,
} from "../../repositories/archiveRepository";
import { IArchive, validateArchive } from "../../interfaces/IArchive";
import { auth } from "../../firebase";
import {
  deleteThumnail,
  getThumnailSrc,
  uploadThumnail,
} from "../../repositories/storageRepository";
import { isMovieJudge } from "../../utils/utilities";
import { uploadArchiveMovie } from "../../repositories/s3Repository";

/**
 * アーカイブ情報の更新ページの状態管理やロジックをまとめたHooks
 */
const useArchiveUpdatePageState = (targetArchiveId: string) => {
  const [state, setState] = useRecoilState(archiveUpdatePageStateAtom);
  const resetState = useResetRecoilState(archiveUpdatePageStateAtom);
  const { openAlert } = useResultAlertState();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: initialArchive } = useQuery(
    ["getArchiveById", targetArchiveId],
    () => getArchiveById(targetArchiveId ?? "")
  );

  useEffect(() => {
    resetState();
    if (initialArchive) {
      setState((prev) => ({
        ...prev,
        title: initialArchive.title,
        description: initialArchive.description,
        thumbnailUrl: initialArchive.thumbnailUrl,
        archiveMovieFileUrl: initialArchive.archiveMovieUrl,
      }));
    }
  }, [initialArchive, setState, resetState]);

  // InputFormの入力値の状態をonChangeで保持
  /**
   * アーカイブタイトルの変更を状態に反映させる。
   */
  const onChangeTitle = useCallback(
    (title: string) => {
      setState((prev) => ({ ...prev, title }));
    },
    [setState]
  );
  /**
   * その他備考欄の内容を状態に反映させる。
   */
  const onChangeDescription = useCallback(
    (description: string) => {
      setState((prev) => ({ ...prev, description }));
    },
    [setState]
  );
  /**
   * サムネイル画像のアップロードを状態に反映させる。
   */
  const onClickUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0] ?? null;
    setState((prev) => ({ ...prev, uploadFile: file }));
  };
  /**
   * アーカイブ動画のアップロードを状態に反映させる。
   */
  const onClickArchiveMovie = (archiveMovieFile: File) => {
    if (!isMovieJudge(archiveMovieFile)) {
      openAlert("error", "動画ファイルをアップロードしてください。");
      setState((prev) => ({ ...prev, archiveMovieFile: null }));
      return;
    }
    setState((prev) => ({ ...prev, archiveMovieFile }));
  };

  /**
   * アーカイブ情報のリフレッシュトリガー
   */
  const onRefresh = () => {
    void queryClient.resetQueries(["getArchiveById", targetArchiveId]);
  };

  /**
   * アーカイブ情報の更新する。
   */
  const updateArchiveInfo = useCallback(async () => {
    if (auth.currentUser == null) {
      return;
    }
    if (!initialArchive || !initialArchive.id) {
      throw new Error("アーカイブ情報の更新に失敗しました。");
    }
    const errors = validateArchive(
      state.title,
      initialArchive.schoolId,
      state.archiveMovieFile?.name ?? state.archiveMovieFileUrl
    );
    if (errors) {
      setState((prev) => ({ ...prev, errors }));
      throw new Error("入力情報を確認してください。");
    }
    setState((prev) => ({ ...prev, errors: undefined }));

    let archiveMovieFileUrl = initialArchive.archiveMovieUrl;
    if (state.archiveMovieFile != null) {
      const archiveMoviePath = await uploadArchiveMovie(
        initialArchive.id,
        state.archiveMovieFile
      );
      archiveMovieFileUrl = archiveMoviePath ?? initialArchive.archiveMovieUrl;
    }
    let thumbnailImageUrl = initialArchive.thumbnailUrl;
    if (state.uploadFile != null) {
      const thumnailPath =
        (await uploadThumnail(
          "archives",
          initialArchive.id,
          state.uploadFile
        )) ?? "";
      thumbnailImageUrl = (await getThumnailSrc(thumnailPath)) ?? "";
    }
    const archive: IArchive = {
      id: initialArchive.id,
      liveId: initialArchive.liveId,
      title: state.title,
      description: state.description,
      thumbnailUrl: thumbnailImageUrl,
      schoolId: initialArchive.schoolId,
      archiveMovieUrl: archiveMovieFileUrl,
      createdAt: initialArchive.createdAt,
      createdUser: auth.currentUser.uid,
    };
    await updateArchive(archive.id!, archive);
  }, [
    initialArchive,
    state.title,
    state.archiveMovieFile,
    state.archiveMovieFileUrl,
    state.uploadFile,
    state.description,
    setState,
  ]);
  const updateMutate = useMutation<void, Error>(() => updateArchiveInfo(), {
    onSuccess: () => {
      openAlert("success", "アーカイブ情報の更新に成功しました。");
      onRefresh();
    },
    onError: (error) => {
      openAlert("error", error.message);
    },
  });

  /**
   * 更新ボタンクリック時の処理
   */
  const onClickUpdate = useCallback(() => {
    void updateMutate.mutate();
  }, [updateMutate]);

  /**
   * アーカイブ情報の削除する。
   */
  const deleteArchiveInfo = useCallback(async () => {
    if (!initialArchive || !initialArchive.id) {
      throw new Error("アーカイブ情報の削除に失敗しました。");
    }
    await deleteThumnail("archives", initialArchive.id);
    await deleteArchive(initialArchive.id);
  }, [initialArchive]);
  const deleteMutate = useMutation<void, Error>(() => deleteArchiveInfo(), {
    onSuccess: () => {
      openAlert("success", "アーカイブ情報の削除に成功しました。");
      navigate(archiveListPagePath);
    },
    onError: (error) => {
      openAlert("error", error.message);
    },
  });

  /**
   * 削除ボタンクリック時の処理
   */
  const onClickDelete = useCallback(() => {
    void deleteMutate.mutate();
  }, [deleteMutate]);

  return {
    state,
    isLoading: updateMutate.isLoading || deleteMutate.isLoading,
    onChangeTitle,
    onChangeDescription,
    onClickUploadFile,
    onClickArchiveMovie,
    onClickUpdate,
    onClickDelete,
  };
};

export default useArchiveUpdatePageState;
