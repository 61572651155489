import { useRecoilState, useResetRecoilState } from "recoil";
import { ChangeEvent, useCallback, useEffect } from "react";
import { useMutation } from "react-query";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import liveRegisterPageStateAtom from "../../recoil/LiveRegisterPage/atom";
import { ILive, validateLive } from "../../interfaces/ILive";
import {
  createLive,
  getLiveById,
  updateLive,
} from "../../repositories/liveRepository";
import { auth } from "../../firebase";
import {
  getThumnailSrc,
  uploadThumnail,
} from "../../repositories/storageRepository";

/**
 * ライブ情報の登録ページの状態管理やロジックをまとめたHooks
 */
const useLiveRegisterPageState = () => {
  const [state, setState] = useRecoilState(liveRegisterPageStateAtom);
  const resetState = useResetRecoilState(liveRegisterPageStateAtom);
  const { openAlert } = useResultAlertState();

  useEffect(() => {
    resetState();
  }, [resetState]);

  // InputFormの入力値の状態をonChangeで保持
  /**
   * ライブタイトルの変更を状態に反映させる。
   */
  const onChangeTitle = useCallback(
    (title: string) => {
      setState((prev) => ({ ...prev, title }));
    },
    [setState]
  );
  /**
   * ライブ開始日時の内容を状態に反映させる。
   */
  const onChangeLiveStartAt = useCallback(
    (liveStartAt: number) => {
      setState((prev) => ({ ...prev, liveStartAt }));
    },
    [setState]
  );
  /**
   * ライブ終了日時の内容を状態に反映させる。
   */
  const onChangeLiveEndAt = useCallback(
    (liveEndAt: number) => {
      setState((prev) => ({ ...prev, liveEndAt }));
    },
    [setState]
  );
  /**
   * その他備考欄の内容を状態に反映させる。
   */
  const onChangeDescription = useCallback(
    (description: string) => {
      setState((prev) => ({ ...prev, description }));
    },
    [setState]
  );
  /**
   * サムネイル画像のアップロードを状態に反映させる。
   */
  const onClickUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0] ?? null;
    setState((prev) => ({ ...prev, uploadFile: file }));
  };
  /**
   * 学校選択の変更を状態に反映させる。
   */
  const onChangeSchool = useCallback(
    (schoolId: string) => {
      setState((prev) => ({ ...prev, schoolId }));
    },
    [setState]
  );

  /**
   * ライブ情報の登録する。
   */
  const registerLive = useCallback(async () => {
    if (auth.currentUser == null) {
      return;
    }
    const errors = validateLive(
      state.title,
      state.schoolId,
      state.liveStartAt,
      state.liveEndAt
    );
    if (errors) {
      setState((prev) => ({ ...prev, errors }));
      throw new Error("入力情報を確認してください。");
    }
    setState((prev) => ({ ...prev, errors: undefined }));

    const live: ILive = {
      id: "",
      liveChannelName: "",
      schoolId: state.schoolId,
      title: state.title,
      thumbnailUrl: "",
      description: state.description,
      liveStartAt: state.liveStartAt,
      liveEndAt: state.liveEndAt,
      createdUser: auth.currentUser.uid,
      createdAt: new Date().getTime(),
      isFinished: false,
    };
    const createdLiveDoc = await createLive(live);
    const createdLive = await getLiveById(createdLiveDoc.id);
    if (createdLive != null) {
      if (state.uploadFile != null) {
        const liveId = createdLiveDoc.id;
        const thumnailPath =
          (await uploadThumnail("lives", liveId, state.uploadFile)) ?? "";
        const thumbnailUrl = (await getThumnailSrc(thumnailPath)) ?? "";
        createdLive.thumbnailUrl = thumbnailUrl;
      }
      await updateLive(createdLiveDoc.id, createdLive);
    }
  }, [
    state.title,
    state.schoolId,
    state.description,
    state.liveStartAt,
    state.liveEndAt,
    state.uploadFile,
    setState,
  ]);
  const registerMutate = useMutation<void, Error>(() => registerLive(), {
    onSuccess: () => {
      openAlert("success", "ライブ情報の作成に成功しました。");
      resetState();
    },
    onError: (error) => {
      openAlert("error", error.message);
    },
  });

  /**
   * 登録ボタンクリック時の処理
   */
  const onClickRegister = useCallback(() => {
    void registerMutate.mutate();
  }, [registerMutate]);

  return {
    state,
    isLoading: registerMutate.isLoading,
    onChangeTitle,
    onChangeSchool,
    onChangeLiveStartAt,
    onChangeLiveEndAt,
    onChangeDescription,
    onClickUploadFile,
    onClickRegister,
  };
};

export default useLiveRegisterPageState;
