import { atom } from "recoil";
import { SchoolErrors } from "../../interfaces/ISchool";

export type SchoolRegisterPageState = {
  /** 学校名 */
  name: string;
  /** コード */
  code: string;
  /** 備考欄 */
  description: string;
  /** サムネイル画像ファイル */
  uploadFile: File | null;
  /** バリデーションエラー */
  errors: SchoolErrors | undefined;
};

const initialState: SchoolRegisterPageState = {
  name: "",
  code: "",
  description: "",
  uploadFile: null,
  errors: undefined,
};

const schoolRegisterPageStateAtom = atom({
  key: "SchoolRegisterPageState",
  default: initialState,
});

export default schoolRegisterPageStateAtom;
