import { CircularProgress } from "@mui/material";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import SchoolUpdatePageLayout from "./SchoolUpdatePageLayout";
import ErrorPage from "../ErrorPage/ErrorPage";

/**
 * 学校情報の更新ページ
 */
const SchoolUpdatePage = () => (
  <ErrorBoundary fallback={<ErrorPage />}>
    <Suspense fallback={<CircularProgress />}>
      <SchoolUpdatePageLayout />
    </Suspense>
  </ErrorBoundary>
);

export default SchoolUpdatePage;
