import { useRecoilState } from "recoil";
import { useCallback } from "react";
import confirmDialogStateAtom from "../../recoil/ConfirmDialog/atom";

/**
 * 確認用ダイアログの状態管理やロジックをまとめたHooks
 */
const useConfirmDialogState = () => {
  const [state, setState] = useRecoilState(confirmDialogStateAtom);

  /**
   * 確認用ダイアログを開く。
   */
  const openDialog = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: true }));
  }, [setState]);

  /**
   * 確認用ダイアログを閉じる。
   */
  const closeDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
    }));
  }, [setState]);

  return {
    state,
    openDialog,
    closeDialog,
  };
};

export default useConfirmDialogState;
