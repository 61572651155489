import { atom } from "recoil";
import { ArchiveErrors } from "../../interfaces/IArchive";

export type ArchiveUpdatePageState = {
  /** アーカイブタイトル */
  title: string;
  /** 備考欄 */
  description: string;
  /** サムネイル画像URL */
  thumbnailUrl: string;
  /** サムネイル画像ファイル */
  uploadFile: File | null;
  /** アーカイブ動画ファイルURL */
  archiveMovieFileUrl: string;
  /** アーカイブ動画ファイル */
  archiveMovieFile: File | null;
  /** バリデーションエラー */
  errors: ArchiveErrors | undefined;
};

const initialState: ArchiveUpdatePageState = {
  title: "",
  description: "",
  thumbnailUrl: "",
  uploadFile: null,
  archiveMovieFileUrl: "",
  archiveMovieFile: null,
  errors: undefined,
};

const archiveUpdatePageStateAtom = atom({
  key: "ArchiveUpdatePageState",
  default: initialState,
});

export default archiveUpdatePageStateAtom;
