import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { FC } from "react";
import useConfirmDialogState from "./useConfirmDialogState";

export type ConfirmDialogProps = {
  title: string;
  description: string;
  onClickExecute: () => void;
};

/**
 * 確認用ダイアログのコンポーネント
 */
const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  description,
  onClickExecute,
}) => {
  const { state, closeDialog } = useConfirmDialogState();
  return (
    <Dialog open={state.isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          {description}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()}>閉じる</Button>
        <Box pl={1} />
        <Button autoFocus variant="contained" onClick={onClickExecute}>
          実行する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
