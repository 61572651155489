import { ReactNode, FC, Suspense } from "react";
import { Navigate } from "react-router";
import { CircularProgress } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import {
  agentUserListPagePath,
  liveListPagePath,
  loginPagePath,
} from "../../layout/urls";
import useAuthGuardState from "./useAuthGuardState";
import ErrorPage from "../ErrorPage/ErrorPage";

type AuthGuardProps = {
  /** 子供コンポーネント */
  children: ReactNode;
  /** ログインしていない状態でしか表示できないページかどうか */
  isNotLoggedInOnly?: boolean;
  /** 管理者ユーザーの状態でしか表示できないページかどうか */
  isAdminOnly?: boolean;
  /** 代理店ユーザーの状態でしか表示できないページかどうか */
  isAgentOnly?: boolean;
  /** 代理店ユーザーもしくは管理者ユーザーの状態でしか表示できないページかどうか */
  isCommon?: boolean;
};

const AuthGuardContent: FC<AuthGuardProps> = ({
  children,
  isNotLoggedInOnly = false,
  isAdminOnly = false,
  isAgentOnly = false,
  isCommon = false,
}) => {
  const { isLoggedInUserType, isLoading } = useAuthGuardState();

  if (isLoading || isLoggedInUserType === undefined) {
    return <CircularProgress />;
  }

  // ログインしていない状態でしか表示できないページ
  if (isNotLoggedInOnly) {
    if (isLoggedInUserType === "admin") {
      return <Navigate to={agentUserListPagePath} />;
    }
    if (isLoggedInUserType === "agent") {
      return <Navigate to={liveListPagePath} />;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  // 代理店ユーザーもしくは管理者ユーザーの状態でしか表示できないページ
  if (isCommon) {
    if (isLoggedInUserType !== "admin" && isLoggedInUserType !== "agent") {
      return <Navigate to={loginPagePath} />;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  // 管理者ユーザーの状態でしか表示できないページ
  if (isAdminOnly) {
    if (isLoggedInUserType !== "admin") {
      return <Navigate to={loginPagePath} />;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  // 代理店ユーザーの状態でしか表示できないページ
  if (isAgentOnly) {
    if (isLoggedInUserType !== "agent") {
      return <Navigate to={loginPagePath} />;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

/**
 * 認証ページアクセス制限コンポーネント
 */
const AuthGuard: FC<AuthGuardProps> = ({
  isNotLoggedInOnly = false,
  isAdminOnly = false,
  isAgentOnly = false,
  isCommon = false,
  children,
}) => (
  <ErrorBoundary fallback={<ErrorPage />}>
    <Suspense fallback={<CircularProgress />}>
      <AuthGuardContent
        isNotLoggedInOnly={isNotLoggedInOnly}
        isAdminOnly={isAdminOnly}
        isAgentOnly={isAgentOnly}
        isCommon={isCommon}
      >
        {children}
      </AuthGuardContent>
    </Suspense>
  </ErrorBoundary>
);

export default AuthGuard;
