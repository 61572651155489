/**
 * 必須項目バリデーション
 */
export const validateRequired = (
  data: string | undefined | null
): string | undefined => {
  if (!data || data === "") {
    return "必須項目です。";
  }
  return undefined;
};

/**
 * メールアドレスのバリデーション(必須項目となります。)
 */
export const validateEmail = (
  email: string | undefined | null
): string | undefined => {
  const requiredValid = validateRequired(email);
  if (requiredValid) {
    return requiredValid;
  }
  if (!email) {
    return "必須項目です。";
  }
  const regex =
    /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  if (regex.test(email)) {
    return undefined;
  }
  return "不正なメールアドレスです。";
};

/**
 * パスワードのバリデーション(必須項目となります。)
 */
export const validatePassword = (
  password: string | undefined | null
): string | undefined => {
  const requiredValid = validateRequired(password);
  if (requiredValid) {
    return requiredValid;
  }
  if (!password) {
    return "必須項目です。";
  }
  const regex = /^([a-zA-Z0-9]{6,})$/;
  if (regex.test(password)) {
    return undefined;
  }
  return "半角英数字の6文字以上にする必要があります。";
};

/**
 * 学校コードのバリデーション(必須項目となります。)
 */
export const validateSchoolCode = (
  code: string | undefined | null,
  schoolCodeList: Array<string>
): string | undefined => {
  const requiredValid = validateRequired(code);
  if (requiredValid) {
    return requiredValid;
  }
  if (!code) {
    return "必須項目です。";
  }
  if (code.length !== 7) {
    return "小文字英数字のみのユニークなID（7文字）を入力してください。";
  }
  const isNotAvailable = schoolCodeList.includes(code);
  if (isNotAvailable) {
    return "入力されたコードは使用不可です。";
  }
  return undefined;
};

/**
 * 現在より未来の日時のバリデーション(必須項目となります。)
 */
export const validateIsFutureDate = (
  date: number | undefined | null
): string | undefined => {
  if (!date || date === -1) {
    return "必須項目です。";
  }
  return undefined;
};
