import { Container, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { loginPagePath } from "../../layout/urls";
import NotHeaderPageBase from "../PageBase/NotHeaderPageBase";

/**
 * 該当のページが存在しない場合のページ
 */
const NotFoundPage = () => (
  <NotHeaderPageBase row={12}>
    <Container>
      <Stack
        direction="column"
        style={{ height: "100vh" }}
        justifyContent="center"
        spacing={4}
      >
        <Stack
          paddingY={6}
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h4" sx={{ color: "#8C8C8C" }}>
            Not Found
          </Typography>
          <Typography variant="body2" sx={{ color: "#8C8C8C" }}>
            お探しのページは見つかりませんでした。
          </Typography>
        </Stack>
        <Typography
          component={Link}
          to={loginPagePath}
          variant="body2"
          color="primary"
        >
          ログインページはこちら
        </Typography>
      </Stack>
    </Container>
  </NotHeaderPageBase>
);

export default NotFoundPage;
