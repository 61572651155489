import { useQuery } from "react-query";
import { useCallback, useEffect, useMemo } from "react";
import {
  useAuthSignInWithEmailAndPassword,
  useAuthUpdatePassword,
} from "@react-query-firebase/auth";
import { useRecoilState, useResetRecoilState } from "recoil";
import { FirebaseError } from "firebase/app";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { auth } from "../../firebase";
import passwordSettingPageStateAtom from "../../recoil/PasswordSettingPage/atom";
import {
  IPasswordSetting,
  PasswordSettingErrors,
  validatePasswordSetting,
} from "../../interfaces/IAuth";
import firebaseError2ErrorMessage from "../../utils/converter";

/**
 * パスワード設定ページの状態管理やロジックをまとめたHooks
 */
const usePasswordSettingPageState = () => {
  const [state, setState] = useRecoilState(passwordSettingPageStateAtom);
  const resetState = useResetRecoilState(passwordSettingPageStateAtom);
  useEffect(() => () => resetState(), [resetState]);
  const { openAlert } = useResultAlertState();

  /**
   * 現在のパスワードの変更を状態に反映させる。
   */
  const onChangeCurrentPassword = useCallback(
    (currentPassword: string) => {
      setState((prev) => ({ ...prev, currentPassword }));
    },
    [setState]
  );

  /**
   * 新しいパスワードの変更を状態に反映させる。
   */
  const onChangeNewPassword = useCallback(
    (newPassword: string) => {
      setState((prev) => ({ ...prev, newPassword }));
    },
    [setState]
  );

  const reSignInMutation = useAuthSignInWithEmailAndPassword(auth);
  const passwordUpdateMutation = useAuthUpdatePassword();

  /**
   * パスワード変更の実行処理
   */
  const passwordSetting = useCallback(async () => {
    if (!auth.currentUser || !auth.currentUser.email) {
      return;
    }
    const passwordSettingItem: IPasswordSetting = {
      currentPassword: state.currentPassword,
      newPassword: state.newPassword,
    };
    const errors = validatePasswordSetting(passwordSettingItem);
    if (errors) {
      setState((prev) => ({ ...prev, errors }));
      return;
    }
    try {
      const res = await reSignInMutation.mutateAsync({
        email: auth.currentUser.email,
        password: state.currentPassword,
      });
      if (res.user) {
        await passwordUpdateMutation.mutateAsync({
          user: auth.currentUser,
          newPassword: state.newPassword,
        });
        openAlert("success", "パスワード変更に成功しました。");
        resetState();
      }
    } catch (err) {
      setState((prev) => ({
        ...prev,
        errors: {
          currentPassword: "現在のパスワードが正しくありません。",
          newPassword: undefined,
        } as PasswordSettingErrors,
      }));
      if (err instanceof FirebaseError) {
        const message = firebaseError2ErrorMessage(err);
        openAlert("error", message);
        return;
      }
      openAlert("error", "パスワード変更に失敗しました。");
    }
  }, [
    openAlert,
    passwordUpdateMutation,
    reSignInMutation,
    resetState,
    setState,
    state.currentPassword,
    state.newPassword,
  ]);

  /**
   * パスワード変更のクエリ定義
   */
  const {
    isLoading: isLoadingPasswordSetting,
    isRefetching: isRefetchingPasswordSetting,
    refetch,
  } = useQuery("passwordSetting", passwordSetting, {
    enabled: false,
    suspense: false,
  });

  /**
   * この内容で変更するボタンクリック時の処理
   */
  const onClickPasswordSetting = useCallback(() => {
    void refetch();
  }, [refetch]);

  const isLoading = useMemo(
    () => isLoadingPasswordSetting || isRefetchingPasswordSetting,
    [isLoadingPasswordSetting, isRefetchingPasswordSetting]
  );

  return {
    state,
    isLoading,
    onChangeCurrentPassword,
    onChangeNewPassword,
    onClickPasswordSetting,
  };
};

export default usePasswordSettingPageState;
