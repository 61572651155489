import { validateEmail, validatePassword } from "../utils/validator";

/**
 * サインイン実行時のインターフェース
 */
export interface ISignIn {
  email: string;
  password: string;
}

/**
 * サインイン時のバリデーション
 */
export type SignInErrors = {
  email: string | undefined;
  password: string | undefined;
};
export const validateSignIn = (auth: ISignIn): SignInErrors | undefined => {
  const errors: SignInErrors = {
    email: undefined,
    password: undefined,
  };
  errors.email = validateEmail(auth.email);
  errors.password = validatePassword(auth.password);
  if (
    Object.values(errors).findIndex(
      (element) => typeof element === "string"
    ) !== -1
  ) {
    return errors;
  }
  return undefined;
};

/**
 * パスワード設定実行時のインターフェース
 */
export interface IPasswordSetting {
  currentPassword: string;
  newPassword: string;
}

/**
 * パスワード設定のバリデーションチェック
 */
export type PasswordSettingErrors = {
  currentPassword: string | undefined;
  newPassword: string | undefined;
};
export const validatePasswordSetting = (
  passwordSetting: IPasswordSetting
): PasswordSettingErrors | undefined => {
  const errors: PasswordSettingErrors = {
    currentPassword: undefined,
    newPassword: undefined,
  };
  errors.currentPassword = validatePassword(passwordSetting.currentPassword);
  errors.newPassword = validatePassword(passwordSetting.newPassword);
  if (
    Object.values(errors).findIndex(
      (element) => typeof element === "string"
    ) !== -1
  ) {
    return errors;
  }
  return undefined;
};
