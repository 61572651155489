import { Credentials } from "aws-sdk";

export const s3Credentials = new Credentials(
  process.env.REACT_APP_S3_ACCESS_KEY_ID!,
  process.env.REACT_APP_S3_SECRET_ACCESS_KEY!
);

export const s3Region = process.env.REACT_APP_S3_REGION;

export const s3Bucket = process.env.REACT_APP_S3_BUCKET;
