import { atom } from "recoil";
import { SignInErrors } from "../../interfaces/IAuth";

export type LoginPageState = {
  /** メールアドレス */
  email: string;
  /** パスワード */
  password: string;
  /** バリデーションエラー */
  errors: SignInErrors | undefined;
};

const initialState: LoginPageState = {
  email: "",
  password: "",
  errors: undefined,
};

const loginPageStateAtom = atom({
  key: "LoginPageState",
  default: initialState,
});

export default loginPageStateAtom;
