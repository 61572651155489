import { atom } from "recoil";

export type AgentUserUpdatePageState = {
  /** 代理店ユーザーID */
  id: string;
  /** 代理店名 */
  name: string;
  /** バリデーションエラー */
  nameError: string | undefined;
};

const initialState: AgentUserUpdatePageState = {
  id: "",
  name: "",
  nameError: undefined,
};

const agentUserUpdatePageStateAtom = atom({
  key: "AgentUserUpdatePageState",
  default: initialState,
});

export default agentUserUpdatePageStateAtom;
