import {
  Container,
  Stack,
  Box,
  Typography,
  Avatar,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useLiveRegisterPageState from "./useLiveRegisterPageState";
import InputForm from "../../components/InputForm/InputForm";
import PageBase from "../PageBase/PageBase";
import BackButton from "../../components/BackButton/BackButton";
import TiTleText from "../../components/TiTleText/TiTleText";
import { onSnapshotSchoolsByAgentUserId } from "../../repositories/schoolRepository";
import { ISchool } from "../../interfaces/ISchool";
import { auth } from "../../firebase";

/**
 * ライブ情報の登録ページ
 */
const LiveRegisterPage = () => {
  const {
    state,
    isLoading,
    onChangeTitle,
    onChangeSchool,
    onChangeLiveStartAt,
    onChangeLiveEndAt,
    onChangeDescription,
    onClickUploadFile,
    onClickRegister,
  } = useLiveRegisterPageState();
  const [schoolList, setSchoolList] = useState<ISchool[]>([]);

  /**
   * 担当学校一覧のリアルタイム取得
   */
  useEffect(() => {
    const sub = onSnapshotSchoolsByAgentUserId((snapshot) => {
      if (snapshot.docs.length > 0) {
        const res = snapshot.docs.map((value) => value.data());
        setSchoolList(res);
      } else {
        setSchoolList([]);
      }
    }, auth.currentUser?.uid ?? "");
    return () => {
      sub();
    };
  }, []);

  return (
    <PageBase row={12}>
      <Container maxWidth="xs">
        <Stack
          paddingY={6}
          direction="column"
          justifyContent="center"
          spacing={4}
        >
          <BackButton />
          <TiTleText
            title="ライブの登録"
            subTitle={`ライブ情報の登録を行います。\n各項目を入力してください。`}
          />
          <Box />
          <Stack
            direction="row"
            justifyContent="start"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              variant="rounded"
              sx={{
                bgcolor: "grey",
                width: 256,
                height: 156,
                margin: "auto",
              }}
              alt={state.title ?? "Thumnail"}
              src={
                state.uploadFile != null
                  ? window.URL.createObjectURL(state.uploadFile)
                  : ""
              }
            >
              No Image
            </Avatar>
            <Box>
              <Button variant="outlined" component="label">
                サムネイル画像の
                <br />
                アップロード
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={onClickUploadFile}
                />
              </Button>
            </Box>
          </Stack>
          <InputForm
            label="ライブタイトル"
            isLabelRequired
            maxLines={1}
            value={state.title}
            onChange={onChangeTitle}
            error={state.errors?.title}
          />
          <FormControl fullWidth>
            <Typography variant="subtitle1" textAlign="start">
              ライブ開始日時
              <span
                style={{
                  color: "red",
                  paddingLeft: "8px",
                }}
              >
                必須
              </span>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                format="YYYY/MM/DD/HH:mm"
                value={dayjs(state.liveStartAt)}
                maxDateTime={dayjs(state.liveEndAt)}
                onChange={(e) => {
                  if (e == null) {
                    return;
                  }
                  onChangeLiveStartAt(dayjs(e).valueOf());
                }}
              />
            </LocalizationProvider>
            <FormHelperText component="div" error={!!state.errors?.liveStartAt}>
              <Typography
                variant="caption"
                style={{ color: state.errors?.liveStartAt ? "red" : "grey" }}
              >
                {state.errors?.liveStartAt}
              </Typography>
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <Typography variant="subtitle1" textAlign="start">
              ライブ終了日時
              <span
                style={{
                  color: "red",
                  paddingLeft: "8px",
                }}
              >
                必須
              </span>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                format="YYYY/MM/DD/HH:mm"
                value={dayjs(state.liveEndAt)}
                minDateTime={dayjs(state.liveStartAt)}
                onChange={(e) => {
                  if (e == null) {
                    return;
                  }
                  onChangeLiveEndAt(dayjs(e).valueOf());
                }}
              />
            </LocalizationProvider>
            <FormHelperText component="div" error={!!state.errors?.liveEndAt}>
              <Typography
                variant="caption"
                style={{ color: state.errors?.liveEndAt ? "red" : "grey" }}
              >
                {state.errors?.liveEndAt}
              </Typography>
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <Typography variant="subtitle1" textAlign="start">
              学校名
              <span
                style={{
                  color: "red",
                  paddingLeft: "8px",
                }}
              >
                必須
              </span>
            </Typography>
            <Select
              value={state.schoolId}
              onChange={(e) => onChangeSchool(e.target.value)}
            >
              {schoolList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText component="div" error={!!state.errors?.schoolId}>
              <Typography
                variant="caption"
                style={{ color: state.errors?.schoolId ? "red" : "grey" }}
              >
                {state.errors?.schoolId}
              </Typography>
            </FormHelperText>
          </FormControl>
          <InputForm
            label="備考"
            multiline
            minLines={3}
            value={state.description}
            onChange={onChangeDescription}
          />
          <Box />
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              borderRadius: "4px",
              elevation: 3,
              py: "16px",
              fontSize: 16,
            }}
            loadingIndicator="読込中..."
            loading={isLoading}
            disabled={isLoading}
            onClick={onClickRegister}
          >
            <Typography color="#ffffff">この内容で登録する</Typography>
          </LoadingButton>
        </Stack>
      </Container>
    </PageBase>
  );
};

export default LiveRegisterPage;
