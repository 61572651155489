import {
  updateDoc,
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  QuerySnapshot,
  onSnapshot,
  addDoc,
  deleteDoc,
  where,
  getDocs,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { ILive, liveConverter } from "../interfaces/ILive";

/**
 * 新規のライブデータの作成する。
 */
export const createLive = async (live: ILive) => {
  const docRef = collection(firestore, "lives").withConverter(liveConverter);
  return addDoc(docRef, live);
};

/**
 * 指定したLive IDのライブ情報を更新する。
 */
export const updateLive = async (liveId: string, live: ILive) => {
  const docRef = doc(firestore, "lives", liveId).withConverter(liveConverter);
  return updateDoc(docRef, live);
};

/**
 * 指定したLive IDのライブ情報を取得する。
 */
export const getLiveById = async (liveId: string): Promise<ILive | null> => {
  if (liveId === "") {
    return null;
  }
  const docRef = doc(firestore, "lives", liveId).withConverter(liveConverter);
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists()) {
    return null;
  }
  return snapshot.data();
};

/**
 * 指定したLive IDのライブ情報を物理削除する。
 */
export const deleteLive = async (liveId: string) => {
  const docRef = doc(firestore, "lives", liveId);
  return deleteDoc(docRef);
};

/**
 * 作成者ユーザーIDに該当するライブ情報を取得する。
 */
export const getLivesByCreatedUser = async (
  createdUser: string
): Promise<ILive[]> => {
  if (createdUser === "") {
    return [];
  }
  const livesCol = collection(firestore, "lives").withConverter(liveConverter);
  const q = query(
    livesCol,
    where("createdUser", "==", createdUser),
    orderBy("isFinished"),
    orderBy("createdAt")
  );
  const snapshot = await getDocs(q);
  const lives: ILive[] = [];
  snapshot.docs.forEach((itemDoc) => {
    const item = itemDoc.data();
    lives.push(item);
  });
  return lives;
};

/**
 * 作成者ユーザーIDに該当するライブ情報をStreamに取得する。
 */
export const onSnapshotLivesByCreatedUser = (
  onChange: (snapshot: QuerySnapshot<ILive>) => void,
  createdUser: string
) => {
  const livesCol = collection(firestore, "lives").withConverter(liveConverter);
  const q = query(
    livesCol,
    where("createdUser", "==", createdUser),
    orderBy("isFinished"),
    orderBy("createdAt")
  );
  const sub = onSnapshot(q, (snapshot) => {
    onChange(snapshot);
  });
  return sub;
};
