import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Container, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { resetPasswordPath } from "../../layout/urls";
import InputForm from "../../components/InputForm/InputForm";
import usePasswordSettingPageState from "./usePasswordSettingPageState";
import PageBase from "../PageBase/PageBase";
import BackButton from "../../components/BackButton/BackButton";
import TiTleText from "../../components/TiTleText/TiTleText";

/**
 * パスワード設定ページ
 */
const PasswordSettingPage = () => {
  const {
    state,
    isLoading,
    onChangeCurrentPassword,
    onChangeNewPassword,
    onClickPasswordSetting,
  } = usePasswordSettingPageState();
  return (
    <PageBase row={12}>
      <Container maxWidth="xs">
        <Stack
          paddingY={6}
          direction="column"
          style={{ height: "100vh" }}
          spacing={4}
        >
          <BackButton />
          <TiTleText
            title="パスワード再設定"
            subTitle={`パスワードの変更を行います。\n現在のパスワードと新しいパスワードを入力してください。`}
          />
          <Box />
          <InputForm
            label="現在のパスワード"
            required
            isLabelRequired
            password
            value={state.currentPassword}
            onChange={onChangeCurrentPassword}
            error={state.errors?.currentPassword}
          />
          <InputForm
            label="新しいパスワード"
            required
            isLabelRequired
            password
            value={state.newPassword}
            onChange={onChangeNewPassword}
            error={state.errors?.newPassword}
          />
          <Box />
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              borderRadius: "4px",
              elevation: 3,
              py: "16px",
              fontSize: 16,
            }}
            loadingIndicator="読込中..."
            loading={isLoading}
            disabled={isLoading}
            onClick={onClickPasswordSetting}
          >
            <Typography color="#ffffff">この内容で再設定する</Typography>
          </LoadingButton>
          <Typography
            component={Link}
            to={resetPasswordPath}
            variant="body2"
            color="secondary"
          >
            現在のパスワードをお忘れの場合
          </Typography>
        </Stack>
      </Container>
    </PageBase>
  );
};

export default PasswordSettingPage;
