import { useLocation } from "react-router-dom";
import {
  Container,
  Stack,
  Typography,
  Box,
  Avatar,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useLiveUpdatePageState from "./useLiveUpdatePageState";
import InputForm from "../../components/InputForm/InputForm";
import PageBase from "../PageBase/PageBase";
import BackButton from "../../components/BackButton/BackButton";
import TiTleText from "../../components/TiTleText/TiTleText";
import useConfirmDialogState from "../../components/ConfirmDialog/useConfirmDialogState";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";

/**
 * ライブ情報の更新ページのレイアウト
 */
const LiveUpdatePageLayout = () => {
  const location = useLocation();
  const targetLiveId = location.state as string;
  const {
    state,
    isLoading,
    onChangeTitle,
    onChangeLiveStartAt,
    onChangeLiveEndAt,
    onChangeDescription,
    onClickUploadFile,
    onClickUpdate,
    onClickDelete,
  } = useLiveUpdatePageState(targetLiveId);
  const { openDialog, closeDialog } = useConfirmDialogState();

  return (
    <PageBase row={12}>
      <Container maxWidth="xs">
        <Stack
          paddingY={6}
          direction="column"
          justifyContent="center"
          spacing={4}
        >
          <BackButton />
          <TiTleText
            title="ライブの更新"
            subTitle={`ライブ情報の更新を行います。\n各項目を入力してください。`}
          />
          <Box />
          <Stack
            direction="row"
            justifyContent="start"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              variant="rounded"
              sx={{
                bgcolor: "grey",
                width: 256,
                height: 156,
                margin: "auto",
              }}
              alt={state.title ?? "Thumnail"}
              src={
                state.uploadFile != null
                  ? window.URL.createObjectURL(state.uploadFile)
                  : state.thumbnailUrl
              }
            >
              No Image
            </Avatar>
            <Box>
              <Button variant="outlined" component="label">
                サムネイル画像の
                <br />
                アップロード
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={onClickUploadFile}
                />
              </Button>
            </Box>
          </Stack>
          <InputForm
            label="ライブタイトル"
            isLabelRequired
            maxLines={1}
            value={state.title}
            onChange={onChangeTitle}
            error={state.errors?.title}
          />
          <FormControl fullWidth>
            <Typography variant="subtitle1" textAlign="start">
              ライブ開始日時
              <span
                style={{
                  color: "red",
                  paddingLeft: "8px",
                }}
              >
                必須
              </span>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                format="YYYY/MM/DD/HH:mm"
                value={dayjs(state.liveStartAt)}
                maxDateTime={dayjs(state.liveEndAt)}
                onChange={(e) => {
                  if (e == null) {
                    return;
                  }
                  onChangeLiveStartAt(dayjs(e).valueOf());
                }}
              />
            </LocalizationProvider>
            <FormHelperText component="div" error={!!state.errors?.liveStartAt}>
              <Typography
                variant="caption"
                style={{ color: state.errors?.liveStartAt ? "red" : "grey" }}
              >
                {state.errors?.liveStartAt}
              </Typography>
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <Typography variant="subtitle1" textAlign="start">
              ライブ終了日時
              <span
                style={{
                  color: "red",
                  paddingLeft: "8px",
                }}
              >
                必須
              </span>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                format="YYYY/MM/DD/HH:mm"
                value={dayjs(state.liveEndAt)}
                minDateTime={dayjs(state.liveStartAt)}
                onChange={(e) => {
                  if (e == null) {
                    return;
                  }
                  onChangeLiveEndAt(dayjs(e).valueOf());
                }}
              />
            </LocalizationProvider>
            <FormHelperText component="div" error={!!state.errors?.liveEndAt}>
              <Typography
                variant="caption"
                style={{ color: state.errors?.liveEndAt ? "red" : "grey" }}
              >
                {state.errors?.liveEndAt}
              </Typography>
            </FormHelperText>
          </FormControl>
          <InputForm
            label="備考"
            multiline
            minLines={3}
            value={state.description}
            onChange={onChangeDescription}
          />
          <Box />
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              borderRadius: "4px",
              elevation: 3,
              py: "16px",
              fontSize: 16,
            }}
            loadingIndicator="読込中..."
            loading={isLoading}
            disabled={isLoading}
            onClick={onClickUpdate}
          >
            <Typography color="#ffffff">この内容で更新する</Typography>
          </LoadingButton>

          <Typography
            variant="subtitle1"
            color="secondary"
            onClick={openDialog}
          >
            <span style={{ cursor: "pointer", borderBottom: "solid 1px" }}>
              ライブ情報を削除する
            </span>
          </Typography>
        </Stack>
      </Container>
      <ConfirmDialog
        title="ライブ情報を削除"
        description="ライブ情報を本当に削除してもよろしいでしょうか？"
        onClickExecute={() => {
          onClickDelete();
          closeDialog();
        }}
      />
    </PageBase>
  );
};

export default LiveUpdatePageLayout;
