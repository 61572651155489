import {
  updateDoc,
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  setDoc,
  QuerySnapshot,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { adminUserConverter, IAdminUser } from "../interfaces/IAdminUser";

/**
 * 新規管理者ユーザーの作成する。
 */
export const createAdminUser = async (adminUser: IAdminUser, uid: string) => {
  const docRef = doc(firestore, "admin_users", uid).withConverter(
    adminUserConverter
  );
  await setDoc(docRef, adminUser);
};

/**
 * 指定したAdminUser IDの管理者ユーザー情報を更新する。
 */
export const updateAdminUser = async (
  adminUserId: string,
  adminUser: IAdminUser
) => {
  const docRef = doc(firestore, "admin_users", adminUserId).withConverter(
    adminUserConverter
  );
  return updateDoc(docRef, adminUser);
};

/**
 * 指定したAdminUser IDの管理者ユーザー情報を取得する。
 */
export const getAdminUserById = async (
  adminUserId: string
): Promise<IAdminUser | null> => {
  if (adminUserId === "") {
    return null;
  }
  const docRef = doc(firestore, "admin_users", adminUserId).withConverter(
    adminUserConverter
  );
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists()) {
    return null;
  }
  return snapshot.data();
};

/**
 * 指定したAdminUser IDの管理者ユーザー情報を論理削除する。
 */
export const deleteAdminUser = async (adminUserId: string) => {
  const docRef = doc(firestore, "admin_users", adminUserId);
  return deleteDoc(docRef);
};

/**
 * すべての管理者ユーザー情報をStreamに取得する。
 */
export const onSnapshotAdminUsers = (
  onChange: (snapshot: QuerySnapshot<IAdminUser>) => void
) => {
  const adminUsersCol = collection(firestore, "admin_users").withConverter(
    adminUserConverter
  );
  const q = query(adminUsersCol, orderBy("createdAt"));
  const sub = onSnapshot(q, (snapshot) => {
    onChange(snapshot);
  });
  return sub;
};
