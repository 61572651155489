import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { validateIsFutureDate, validateRequired } from "../utils/validator";

/**
 * ライブ情報を表現するインターフェース
 */
export interface ILive {
  id?: string;
  liveChannelName: string;
  schoolId: string;
  title: string;
  description: string;
  thumbnailUrl: string;
  liveStartAt: number;
  liveEndAt: number;
  createdUser: string;
  createdAt: number;
  isFinished: boolean;
}

/**
 * ライブ情報のバリデーション。
 */
export type LiveErrors = {
  title: string | undefined;
  schoolId: string | undefined;
  liveStartAt: string | undefined;
  liveEndAt: string | undefined;
};
export const validateLive = (
  title: string,
  schoolId: string,
  liveStartAt: number,
  liveEndAt: number
): LiveErrors | undefined => {
  const errors: LiveErrors = {
    title: undefined,
    schoolId: undefined,
    liveStartAt: undefined,
    liveEndAt: undefined,
  };
  errors.title = validateRequired(title);
  errors.schoolId = validateRequired(schoolId);
  errors.liveStartAt = validateIsFutureDate(liveStartAt);
  errors.liveEndAt = validateIsFutureDate(liveEndAt);
  if (
    Object.values(errors).findIndex(
      (element) => typeof element === "string"
    ) !== -1
  ) {
    return errors;
  }
  return undefined;
};

/**
 * Firestoreからの情報取得用、データコンバータ。
 * Firestoreからのsnapshotと、ライブ情報の型を変換する。
 */
export const liveConverter: FirestoreDataConverter<ILive> = {
  toFirestore: (live: ILive): DocumentData => ({
    liveChannelName: live.liveChannelName,
    schoolId: live.schoolId,
    title: live.title,
    description: live.description,
    thumbnailUrl: live.thumbnailUrl,
    liveStartAt: live.liveStartAt,
    liveEndAt: live.liveEndAt,
    createdUser: live.createdUser,
    createdAt: live.createdAt,
    isFinished: live.isFinished,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ILive => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      liveChannelName: data.liveChannelName as string,
      schoolId: data.schoolId as string,
      title: data.title as string,
      description: data.description as string,
      thumbnailUrl: data.thumbnailUrl as string,
      liveStartAt: data.liveStartAt as number,
      liveEndAt: data.liveEndAt as number,
      createdUser: data.createdUser as string,
      createdAt: data.createdAt as number,
      isFinished: data.isFinished as boolean,
    } as ILive;
  },
};
