import { Box, Container, Grid, Stack } from "@mui/material";
import { FC, ReactNode } from "react";
import useBreakpoint from "../../utils/useBreakPoint";

/**
 * ログインページのベースコンポーネント
 */
const NotHeaderPageBase: FC<{ children?: ReactNode; row: number }> = ({
  children = null,
  row,
}) => {
  const { innerHeight, breakpoint } = useBreakpoint();

  const desktopSizeWidgets = (
    <Container maxWidth={false} style={{ padding: 0 }}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item alignItems="center" justifyContent="center" xs={row}>
          <Box bgcolor="#FFFFFF" minHeight="100vh">
            {children}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );

  const tabletSizeWidgets = (
    <Container maxWidth={false} style={{ padding: 0 }}>
      <Box bgcolor="#FFFFFF">
        <Stack
          direction="column"
          justifyContent="center"
          minHeight={innerHeight}
        >
          {children}
        </Stack>
      </Box>
    </Container>
  );

  switch (breakpoint) {
    case "xs":
    case "sm":
      return tabletSizeWidgets;
    case "md":
    case "lg":
    case "xl":
      return desktopSizeWidgets;
    default:
      return desktopSizeWidgets;
  }
};

export default NotHeaderPageBase;
