import { useRecoilState, useResetRecoilState } from "recoil";
import { ChangeEvent, useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { liveListPagePath } from "../../layout/urls";
import liveUpdatePageStateAtom from "../../recoil/LiveUpdatePage/atom";
import {
  deleteLive,
  getLiveById,
  updateLive,
} from "../../repositories/liveRepository";
import { ILive, validateLive } from "../../interfaces/ILive";
import {
  deleteThumnail,
  getThumnailSrc,
  uploadThumnail,
} from "../../repositories/storageRepository";

/**
 * ライブ情報の更新ページの状態管理やロジックをまとめたHooks
 */
const useLiveUpdatePageState = (targetLiveId: string) => {
  const [state, setState] = useRecoilState(liveUpdatePageStateAtom);
  const resetState = useResetRecoilState(liveUpdatePageStateAtom);
  const { openAlert } = useResultAlertState();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: initialLive } = useQuery(["getLiveById", targetLiveId], () =>
    getLiveById(targetLiveId ?? "")
  );

  useEffect(() => {
    resetState();
    if (initialLive) {
      setState((prev) => ({
        ...prev,
        title: initialLive.title,
        liveStartAt: initialLive.liveStartAt,
        liveEndAt: initialLive.liveEndAt,
        description: initialLive.description,
        thumbnailUrl: initialLive.thumbnailUrl,
      }));
    }
  }, [initialLive, setState, resetState]);

  // InputFormの入力値の状態をonChangeで保持
  /**
   * ライブタイトルの変更を状態に反映させる。
   */
  const onChangeTitle = useCallback(
    (title: string) => {
      setState((prev) => ({ ...prev, title }));
    },
    [setState]
  );
  /**
   * ライブ開始日時の内容を状態に反映させる。
   */
  const onChangeLiveStartAt = useCallback(
    (liveStartAt: number) => {
      setState((prev) => ({ ...prev, liveStartAt }));
    },
    [setState]
  );
  /**
   * ライブ終了日時の内容を状態に反映させる。
   */
  const onChangeLiveEndAt = useCallback(
    (liveEndAt: number) => {
      setState((prev) => ({ ...prev, liveEndAt }));
    },
    [setState]
  );
  /**
   * その他備考欄の内容を状態に反映させる。
   */
  const onChangeDescription = useCallback(
    (description: string) => {
      setState((prev) => ({ ...prev, description }));
    },
    [setState]
  );
  /**
   * サムネイル画像のアップロードを状態に反映させる。
   */
  const onClickUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0] ?? null;
    setState((prev) => ({ ...prev, uploadFile: file }));
  };

  /**
   * ライブ情報のリフレッシュトリガー
   */
  const onRefresh = () => {
    void queryClient.resetQueries(["getLiveById", targetLiveId]);
  };

  /**
   * ライブ情報の更新する。
   */
  const updateLiveInfo = useCallback(async () => {
    if (!initialLive || !initialLive.id) {
      throw new Error("ライブ情報の更新に失敗しました。");
    }
    const errors = validateLive(
      state.title,
      initialLive.schoolId,
      state.liveStartAt,
      state.liveEndAt
    );
    if (errors) {
      setState((prev) => ({ ...prev, errors }));
      throw new Error("入力情報を確認してください。");
    }
    setState((prev) => ({ ...prev, errors: undefined }));

    let thumbnailImageUrl = initialLive.thumbnailUrl;
    if (state.uploadFile != null) {
      const thumnailPath =
        (await uploadThumnail("lives", initialLive.id, state.uploadFile)) ?? "";
      thumbnailImageUrl = (await getThumnailSrc(thumnailPath)) ?? "";
    }
    const live: ILive = {
      id: initialLive.id,
      liveChannelName: initialLive.liveChannelName,
      schoolId: initialLive.schoolId,
      title: state.title,
      description: state.description,
      thumbnailUrl: thumbnailImageUrl,
      liveStartAt: state.liveStartAt,
      liveEndAt: state.liveEndAt,
      createdUser: initialLive.createdUser,
      createdAt: initialLive.createdAt,
      isFinished: initialLive.isFinished,
    };
    await updateLive(live.id!, live);
  }, [
    initialLive,
    state.title,
    state.uploadFile,
    state.description,
    state.liveStartAt,
    state.liveEndAt,
    setState,
  ]);
  const updateMutate = useMutation<void, Error>(() => updateLiveInfo(), {
    onSuccess: () => {
      openAlert("success", "ライブ情報の更新に成功しました。");
      onRefresh();
    },
    onError: (error) => {
      openAlert("error", error.message);
    },
  });

  /**
   * 更新ボタンクリック時の処理
   */
  const onClickUpdate = useCallback(() => {
    void updateMutate.mutate();
  }, [updateMutate]);

  /**
   * ライブ情報の削除する。
   */
  const deleteLiveInfo = useCallback(async () => {
    if (!initialLive || !initialLive.id) {
      throw new Error("ライブ情報の削除に失敗しました。");
    }
    await deleteThumnail("lives", initialLive.id);
    await deleteLive(initialLive.id);
  }, [initialLive]);
  const deleteMutate = useMutation<void, Error>(() => deleteLiveInfo(), {
    onSuccess: () => {
      openAlert("success", "ライブ情報の削除に成功しました。");
      navigate(liveListPagePath);
    },
    onError: (error) => {
      openAlert("error", error.message);
    },
  });

  /**
   * 削除ボタンクリック時の処理
   */
  const onClickDelete = useCallback(() => {
    void deleteMutate.mutate();
  }, [deleteMutate]);

  return {
    state,
    isLoading: updateMutate.isLoading || deleteMutate.isLoading,
    onChangeTitle,
    onChangeLiveStartAt,
    onChangeLiveEndAt,
    onChangeDescription,
    onClickUploadFile,
    onClickUpdate,
    onClickDelete,
  };
};

export default useLiveUpdatePageState;
