import { encode as base64Encode } from "base-64";
import { getFunctions, httpsCallable } from "firebase/functions";

export const agoraConstants = {
  appId: process.env.REACT_APP_AGORA_APP_ID ?? "",
  customerKey: process.env.REACT_APP_AGORA_CUSTOMER_KEY ?? "",
  customerSecret: process.env.REACT_APP_AGORA_CUSTOMER_SECRET ?? "",
};

export const agoraRecordingConstants = {
  aquireRecordingUrl: `https://api.agora.io/v1/apps/${agoraConstants.appId}/cloud_recording/acquire`,
};

export const generateAgoraAuthorization = () => {
  const plainCred = `${agoraConstants.customerKey}:${agoraConstants.customerSecret}`;
  const base64Cred = base64Encode(plainCred);
  return base64Cred;
};

export const startRecordingUrl = (resourceId: string) =>
  `https://api.agora.io/v1/apps/${agoraConstants.appId}/cloud_recording/resourceid/${resourceId}/mode/composite/start`;

export const stopRecordingUrl = (resourceId: string, sid: string) =>
  `https://api.agora.io/v1/apps/${agoraConstants.appId}/cloud_recording/resourceid/${resourceId}/sid/${sid}/mode/composite/stop`;

export const generateAgoraToken = async (
  uid: number,
  liveChannelName: string
) => {
  // Token取得
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  const generateAgoraTokenFunction = httpsCallable<unknown, string>(
    functions,
    "generateAgoraToken"
  );
  const reqParam = {
    uid,
    channel: liveChannelName,
    role: "publisher",
  };
  console.log(reqParam);

  const tokenRes = await generateAgoraTokenFunction(reqParam);
  console.log(tokenRes);
  return tokenRes.data;
};
