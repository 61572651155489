import { useRecoilState, useResetRecoilState } from "recoil";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { IAgentUser } from "../../interfaces/IAgentUser";
import {
  deleteAgentUser,
  getAgentUserById,
  updateAgentUser,
} from "../../repositories/agentUserRepository";
import agentUserUpdatePageStateAtom from "../../recoil/AgentUserUpdatePage/atom";
import { validateRequired } from "../../utils/validator";
import { agentUserListPagePath } from "../../layout/urls";

/**
 * 代理店ユーザー更新ページの状態管理やロジックをまとめたHooks
 */
const useAgentUserUpdatePageState = (targetAgentUserId: string) => {
  const [state, setState] = useRecoilState(agentUserUpdatePageStateAtom);
  const resetState = useResetRecoilState(agentUserUpdatePageStateAtom);
  const { openAlert } = useResultAlertState();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: initialAgentUser } = useQuery(
    ["getAgentUserById", targetAgentUserId],
    () => getAgentUserById(targetAgentUserId)
  );

  useEffect(() => {
    resetState();
    if (initialAgentUser) {
      setState((prev) => ({
        ...prev,
        id: initialAgentUser.id,
        name: initialAgentUser.name,
      }));
    }
  }, [initialAgentUser, setState, resetState]);

  // InputFormの入力値の状態をonChangeで保持
  /**
   * 代理店名の変更を状態に反映させる。
   */
  const onChangeName = useCallback(
    (name: string) => {
      setState((prev) => ({ ...prev, name }));
    },
    [setState]
  );

  /**
   * 代理店ユーザー情報のリフレッシュトリガー
   */
  const onRefresh = () => {
    resetState();
    void queryClient.resetQueries(["getAgentUserById", targetAgentUserId]);
  };

  /**
   * 代理店ユーザー情報の更新する。
   */
  const updateAgentUserInfo = useCallback(async () => {
    if (!initialAgentUser) {
      throw new Error("代理店ユーザーの更新に失敗しました。");
    }
    const nameError = validateRequired(state.name);
    if (nameError) {
      setState((prev) => ({
        ...prev,
        nameError,
      }));
      throw new Error("入力情報を確認してください。");
    }
    setState((prev) => ({
      ...prev,
      nameError: undefined,
      schoolIdError: undefined,
    }));

    const agentUser: IAgentUser = {
      id: initialAgentUser.id,
      name: state.name,
      isDeleted: false,
      createdAt: initialAgentUser.createdAt,
    };
    await updateAgentUser(agentUser.id, agentUser);
  }, [initialAgentUser, state.name, setState]);
  const updateMutate = useMutation<void, Error>(() => updateAgentUserInfo(), {
    onSuccess: () => {
      openAlert("success", "代理店ユーザーの更新に成功しました。");
      void queryClient.resetQueries(["getUserName", targetAgentUserId]);
      onRefresh();
    },
    onError: (error) => {
      openAlert("error", error.message);
    },
  });

  /**
   * 更新ボタンクリック時の処理
   */
  const onClickUpdate = useCallback(() => {
    void updateMutate.mutate();
  }, [updateMutate]);

  /**
   * 代理店ユーザー情報の削除する。
   */
  const deleteAgentUserInfo = useCallback(async () => {
    if (!initialAgentUser) {
      throw new Error("代理店ユーザーの削除に失敗しました。");
    }
    await deleteAgentUser(initialAgentUser.id);
  }, [initialAgentUser]);
  const deleteMutate = useMutation<void, Error>(() => deleteAgentUserInfo(), {
    onSuccess: () => {
      openAlert("success", "代理店ユーザーの削除に成功しました。");
      navigate(agentUserListPagePath);
    },
    onError: (error) => {
      openAlert("error", error.message);
    },
  });

  /**
   * 削除ボタンクリック時の処理
   */
  const onClickDelete = useCallback(() => {
    void deleteMutate.mutate();
  }, [deleteMutate]);

  return {
    state,
    isLoading: updateMutate.isLoading || deleteMutate.isLoading,
    onChangeName,
    onClickUpdate,
    onClickDelete,
  };
};

export default useAgentUserUpdatePageState;
