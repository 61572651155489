import { useQuery } from "react-query";
import { useRecoilState, useResetRecoilState } from "recoil";
import authGuardStateAtom from "../../recoil/AuthGuard/atom";
import { getAdminUserById } from "../../repositories/adminUserRepository";
import { getAgentUserById } from "../../repositories/agentUserRepository";
import { auth } from "../../firebase";

const useAuthGuardState = () => {
  const [, setState] = useRecoilState(authGuardStateAtom);
  const resetState = useResetRecoilState(authGuardStateAtom);

  const { data: isLoggedInUserType, isLoading: isLoadingGetAuthUser } =
    useQuery(["getAuthUser", auth.currentUser?.uid ?? ""], async () => {
      const agentUser = await getAgentUserById(auth.currentUser?.uid ?? "");
      if (agentUser) {
        setState((_) => ({
          userId: agentUser.id,
          userType: "agent",
        }));
        return "agent";
      }
      const adminUser = await getAdminUserById(auth.currentUser?.uid ?? "");
      if (adminUser) {
        setState((_) => ({
          userId: adminUser.id,
          userType: "admin",
        }));
        return "admin";
      }
      resetState();
      return "";
    });

  return {
    isLoggedInUserType,
    isLoading: isLoadingGetAuthUser,
  };
};

export default useAuthGuardState;
