import { Alert, Snackbar } from "@mui/material";
import useResultAlertState from "./useResultAlertState";

/**
 * アラートコンポーネント
 */
const ResultAlert = () => {
  const { state, closeAlert } = useResultAlertState();

  return (
    <Snackbar
      open={state.isOpen}
      autoHideDuration={1000}
      onClose={closeAlert}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={closeAlert}
        severity={state.result}
        sx={{ width: "100%", textAlign: "start" }}
      >
        {state.message}
      </Alert>
    </Snackbar>
  );
};

export default ResultAlert;
