import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { cloneDeep } from "lodash";
import { RtcPropsInterface } from "agora-react-uikit";
import { collection, doc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from "axios";
import { getLiveById, updateLive } from "../../repositories/liveRepository";
import liveDetailPageStateAtom from "../../recoil/LiveDetailPage/atom";
import {
  agoraConstants,
  agoraRecordingConstants,
  generateAgoraAuthorization,
  generateAgoraToken,
  startRecordingUrl,
  stopRecordingUrl,
} from "../../utils/agoraConstants";
import { createArchive } from "../../repositories/archiveRepository";
import { auth, firestore } from "../../firebase";
import { archiveConverter } from "../../interfaces/IArchive";

export type AcquireRecordingResponse = {
  cname: string;
  resourceId: string;
  uid: number;
};

export type StartRecordingResponse = {
  cname: string;
  resourceId: string;
  sid: string;
};

export type StopRecordingResponse = {
  resourceId: string;
  sid: string;
  serverResponse: {
    fileListMode: string;
    fileList:
      | {
          fileName: string;
          trackType: string;
          uid: string;
          mixedAllUser: boolean;
          isPlayable: boolean;
          sliceStartTime: number;
        }[]
      | string;
    uploadingStatus: string;
  };
};

const useLiveDetailPageState = (targetLiveId: string) => {
  const [state, setState] = useRecoilState(liveDetailPageStateAtom);
  const resetState = useResetRecoilState(liveDetailPageStateAtom);
  const queryClient = useQueryClient();
  const [agoraParams, setAgoraParams] = useState<RtcPropsInterface | null>(
    null
  );
  const [archiveId, setArchiveId] = useState("");

  const [resourceIdState, setResourceIdState] = useState<string>("");
  const [sidState, setSidState] = useState<string>("");

  const { data: initialLive, isLoading: isLoadingGetLive } = useQuery(
    ["getLiveById", targetLiveId],
    () => getLiveById(targetLiveId ?? "")
  );

  useEffect(() => {
    resetState();
    if (initialLive) {
      setState((prev) => ({
        live: initialLive,
      }));
    }
  }, [initialLive, setState, resetState]);

  // アーカイブ動画のレコーディングスタート
  const startRecording = async (liveChannelName: string) => {
    console.log("--- start recording ---");
    const uid = 99999;

    const authorization = generateAgoraAuthorization();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${authorization}`,
    };

    const aquireResponse = await axios.post<AcquireRecordingResponse>(
      agoraRecordingConstants.aquireRecordingUrl,
      {
        cname: liveChannelName,
        // TODO: クライアント側のuidと被らないように
        uid: `${uid}`,
        clientRequest: {},
      },
      { headers }
    );
    console.log(aquireResponse.data);
    const { resourceId } = aquireResponse.data;
    console.debug(resourceId);
    if (!resourceId) {
      // TODO: エラー対応
      console.error("Resource ID was not found");
      return;
    }

    setResourceIdState(resourceId);

    const token = await generateAgoraToken(uid, liveChannelName);

    const archiveColRef = collection(firestore, "archives").withConverter(
      archiveConverter
    );
    const archiveDocRef = doc(archiveColRef);
    setArchiveId(archiveDocRef.id);

    const functions = getFunctions();
    functions.region = "asia-northeast1";
    const startRecordingFunction = httpsCallable<
      unknown,
      StartRecordingResponse
    >(functions, "startRecording");
    const reqParam = {
      uid: `${uid}`,
      channel: liveChannelName,
      token,
      resourceId,
      authorization,
      archiveId: archiveDocRef.id,
    };
    const startRecordingResponse = await startRecordingFunction(reqParam);

    console.log(startRecordingResponse);
    console.log(startRecordingResponse.data);

    setSidState(startRecordingResponse.data.sid);
    console.log(startRecordingResponse.data.sid);
  };

  const stopRecording = async (liveChannelName: string) => {
    console.log("--- stopRecording ---");
    const uid = 99999;

    if (auth.currentUser == null) {
      return;
    }

    if (resourceIdState === "") {
      console.log("resourceId is not set");
      return;
    }
    if (sidState === "") {
      console.log("sid is not set");
      return;
    }
    const liveData = state.live;
    if (liveData == null) {
      console.log("state.live is undefined");
      return;
    }
    if (liveData.id == null) {
      return;
    }

    const authorization = generateAgoraAuthorization();

    const functions = getFunctions();
    functions.region = "asia-northeast1";
    const stopRecordingFunction = httpsCallable<unknown, StopRecordingResponse>(
      functions,
      "stopRecording"
    );
    const reqParam = {
      uid: `${uid}`,
      channel: liveChannelName,
      resourceId: resourceIdState,
      authorization,
      sid: sidState,
    };
    const stopRecordingResponse = await stopRecordingFunction(reqParam);
    console.log(stopRecordingResponse);
    console.log(stopRecordingResponse.data);

    await createArchive(
      {
        liveId: liveData.id,
        title: liveData.title,
        description: liveData.description,
        schoolId: liveData.schoolId,
        thumbnailUrl: "",
        archiveMovieUrl:
          typeof stopRecordingResponse.data.serverResponse.fileList === "string"
            ? stopRecordingResponse.data.serverResponse.fileList
            : stopRecordingResponse.data.serverResponse.fileList[0].fileName,
        createdAt: new Date().getTime(),
        createdUser: auth.currentUser.uid,
      },
      archiveId
    );
  };

  // ライブ配信開始ボタンクリック時処理
  const startLive = async () => {
    console.log("--- start live ---");
    if (state.live == null) {
      console.log("Not loaded live");
      return;
    }
    console.log(state.live);

    const live = cloneDeep(state.live);
    if (live.id == null) {
      return;
    }

    // 配信開始時にAgora channel nameを更新して、配信中ステータスをクライアントに通知
    const liveChannelName = live.id;
    live.liveChannelName = liveChannelName;
    await updateLive(live.id, live);

    setState((prev) => ({ ...prev, live }));

    // TODO: current uid
    const uid = Math.floor(Math.random() * 10 ** 5);

    // Token取得
    const token = await generateAgoraToken(uid, liveChannelName);

    // Agora SDKにより、ライブを作成
    const rtcProps: RtcPropsInterface = {
      uid,
      appId: agoraConstants.appId,
      channel: liveChannelName,
      token,
      role: "host",
      disableRtm: true,
    };
    setAgoraParams(rtcProps);

    // ライブ配信が開始されてないとRecordingがエラーで返ってくるので３秒後に開始する。
    setTimeout(() => {
      void startRecording(liveChannelName);
    }, 3000);
  };

  const startLiveMutate = useMutation<void, Error>(() => startLive(), {
    onError: (error, variables, context) => {
      console.log(error);
      setAgoraParams(null);
    },
  });

  const onClickLiveStart = useCallback(() => {
    void startLiveMutate.mutate();
  }, [startLiveMutate]);

  const stopLive = async () => {
    if (state.live == null) {
      console.log("Not loaded live");
      return;
    }
    console.log(state.live);

    try {
      await stopRecording(state.live.liveChannelName);
    } catch (e) {
      console.error(e);
    }

    setResourceIdState("");
    setSidState("");

    // 配信終了時にAgora channel nameを空文字にして、未配信ステータスをクライアントに通知
    const live = cloneDeep(state.live);
    if (live.id == null) {
      return;
    }
    live.liveChannelName = "";
    live.isFinished = true;
    await updateLive(live.id, live);
    setAgoraParams(null);
    void queryClient.resetQueries(["getLiveById", targetLiveId]);
  };

  const stopLiveMutate = useMutation<void, Error>(() => stopLive());

  const onClickLiveStop = useCallback(() => {
    void stopLiveMutate.mutate();
  }, [stopLiveMutate]);

  const agoraCallbacks = {
    EndCall: () => {
      console.log("Ended call");
      stopLiveMutate.mutate();
    },
  };

  return {
    state,
    isLoadingGetLive,
    isLoading:
      isLoadingGetLive || startLiveMutate.isLoading || stopLiveMutate.isLoading,
    onClickLiveStart,
    agoraParams,
    agoraCallbacks,
    onClickLiveStop,
  };
};

export default useLiveDetailPageState;
