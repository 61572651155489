import { FirebaseError } from "firebase/app";
import { ReactNode } from "react";

/**
 * Date型からstringへ変換しフォーマットして返す。
 */
export const date2String = (date: Date) =>
  `${date.getFullYear()}/${
    date.getMonth() + 1
  }/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;

/**
 * FirebaseErrorから対応するエラーメッセージを返す。
 */
const firebaseError2ErrorMessage = (error: FirebaseError): ReactNode => {
  switch (error.code) {
    case "auth/email-already-exists":
      return (
        <div>
          メールアドレスをご確認の上、再度お試しください。
          <br />
          エラーコード: 101
        </div>
      );
    case "auth/id-token-expired":
    case "auth/id-token-revoked":
    case "auth/session-cookie-expired":
    case "auth/session-cookie-revoked":
      return (
        <div>
          タイムアウトしました。ページを再読み込みして、再度お試しください。
          <br />
          エラーコード：102
        </div>
      );
    case "auth/invalid-argument":
      return (
        <div>
          不正な値が送信されました。入力内容を確認して、再度お試しください。
          <br />
          エラーコード：103
        </div>
      );
    case "auth/email-already-in-use":
    case "auth/invalid-email":
      return (
        <div>
          メールアドレスをご確認の上、再度お試しください。
          <br />
          エラーコード：104
        </div>
      );
    case "auth/invalid-password":
    case "auth/wrong-password":
      return (
        <div>
          パスワードをご確認の上、再度お試しください。
          <br />
          エラーコード：105
        </div>
      );
    case "auth/too-many-requests":
      return (
        <div>
          時間を空けてから、再度お試しください。
          <br />
          エラーコード：106
        </div>
      );
    case "auth/user-not-found":
      return (
        <div>
          メールアドレスまたはパスワードが間違っています。
          <br />
          エラーコード：107
        </div>
      );
    default:
      return (
        <div>
          不明なエラーが発生しました。管理者へお問い合わせ下さい。
          <br />
          エラーコード：${error.code}
          <br />
          内容：${error.message}
        </div>
      );
  }
};

export default firebaseError2ErrorMessage;
