import { atom } from "recoil";

export type ConfirmDialogState = {
  /** ダイアログ表示かどうか */
  isOpen: boolean;
};

const initialState: ConfirmDialogState = {
  isOpen: false,
};

const confirmDialogStateAtom = atom({
  key: "ConfirmDialogState",
  default: initialState,
});

export default confirmDialogStateAtom;
