import { useRecoilState, useResetRecoilState } from "recoil";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import {
  deleteSchool,
  getSchoolsByAgentUserId,
} from "../../repositories/schoolRepository";
import agentUserDetailPageStateAtom from "../../recoil/AgentUserDetailPage/atom";
import {
  deleteLive,
  getLivesByCreatedUser,
} from "../../repositories/liveRepository";
import {
  deleteArchive,
  getArchivesByCreatedUser,
} from "../../repositories/archiveRepository";
import { getAgentUserById } from "../../repositories/agentUserRepository";

/**
 * 代理店ユーザーの詳細ページの状態管理やロジックをまとめたHooks
 */
const useAgentUserDetailPageState = (targetAgentUserId: string) => {
  const [state, setState] = useRecoilState(agentUserDetailPageStateAtom);
  const resetState = useResetRecoilState(agentUserDetailPageStateAtom);
  const { openAlert } = useResultAlertState();
  const queryClient = useQueryClient();

  const { data: initialAgentUser } = useQuery(
    ["getAgentUserById", targetAgentUserId],
    () => getAgentUserById(targetAgentUserId)
  );

  const { data: initialLives } = useQuery(
    ["getLivesByCreatedUser", targetAgentUserId],
    () => getLivesByCreatedUser(targetAgentUserId)
  );

  const { data: initialArchives } = useQuery(
    ["getArchivesByCreatedUser", targetAgentUserId],
    () => getArchivesByCreatedUser(targetAgentUserId)
  );

  const { data: initialSchools } = useQuery(
    ["getSchoolsByAgentUserId", targetAgentUserId],
    () => getSchoolsByAgentUserId(targetAgentUserId)
  );

  useEffect(() => {
    if (initialAgentUser) {
      setState((prev) => ({
        ...prev,
        agentUser: initialAgentUser,
      }));
    }
  }, [initialAgentUser, setState, resetState]);

  useEffect(() => {
    if (initialLives) {
      setState((prev) => ({
        ...prev,
        lives: initialLives,
      }));
    }
  }, [initialLives, setState, resetState]);

  useEffect(() => {
    if (initialArchives) {
      setState((prev) => ({
        ...prev,
        archives: initialArchives,
      }));
    }
  }, [initialArchives, setState, resetState]);

  useEffect(() => {
    if (initialSchools) {
      setState((prev) => ({
        ...prev,
        schools: initialSchools,
      }));
    }
  }, [initialSchools, setState, resetState]);

  /**
   * リフレッシュトリガー
   */
  const onRefresh = () => {
    void queryClient.resetQueries(["getAgentUserById", targetAgentUserId]);
    void queryClient.resetQueries(["getLivesByCreatedUser", targetAgentUserId]);
    void queryClient.resetQueries([
      "getArchivesByCreatedUser",
      targetAgentUserId,
    ]);
    void queryClient.resetQueries([
      "getSchoolsByAgentUserId",
      targetAgentUserId,
    ]);
  };

  /**
   * ライブ情報を一件削除する
   */
  const onClickLiveDelete = async (liveId: string) => {
    try {
      await deleteLive(liveId);
      await queryClient.resetQueries([
        "getLivesByCreatedUser",
        targetAgentUserId,
      ]);
      openAlert("success", "ライブ情報の削除に成功しました。");
    } catch {
      openAlert("error", "ライブ情報の削除に失敗しました。");
    }
  };

  /**
   * アーカイブ情報を一件削除する
   */
  const onClickArchiveDelete = async (archiveId: string) => {
    try {
      await deleteArchive(archiveId);
      await queryClient.resetQueries([
        "getArchivesByCreatedUser",
        targetAgentUserId,
      ]);
      openAlert("success", "アーカイブ情報の削除に成功しました。");
    } catch {
      openAlert("error", "アーカイブ情報の削除に失敗しました。");
    }
  };

  /**
   * 学校情報を一件削除する
   */
  const onClickSchoolDelete = async (schoolId: string) => {
    try {
      await deleteSchool(schoolId);
      await queryClient.resetQueries([
        "getSchoolsByAgentUserId",
        targetAgentUserId,
      ]);
      openAlert("success", "学校情報の削除に成功しました。");
    } catch {
      openAlert("error", "学校情報の削除に失敗しました。");
    }
  };

  return {
    state,
    onRefresh,
    onClickLiveDelete,
    onClickArchiveDelete,
    onClickSchoolDelete,
  };
};

export default useAgentUserDetailPageState;
