import { atom } from "recoil";
import { ISchool } from "../../interfaces/ISchool";
import { ILive } from "../../interfaces/ILive";
import { IArchive } from "../../interfaces/IArchive";
import { IAgentUser } from "../../interfaces/IAgentUser";

export type AgentUserDetailPageState = {
  /** 代理店ユーザー情報 */
  agentUser: IAgentUser | null;
  /** ライブ一覧 */
  lives: ILive[];
  /** アーカイブ一覧 */
  archives: IArchive[];
  /** 学校一覧 */
  schools: ISchool[];
};

const initialState: AgentUserDetailPageState = {
  agentUser: null,
  lives: [],
  archives: [],
  schools: [],
};

const agentUserDetailPageStateAtom = atom({
  key: "AgentUserDetailPageState",
  default: initialState,
});

export default agentUserDetailPageStateAtom;
