import { useDropzone } from "react-dropzone";
import { useLocation } from "react-router-dom";
import {
  Container,
  Stack,
  Typography,
  Box,
  Avatar,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ChangeEvent, useCallback, useMemo } from "react";
import useArchiveUpdatePageState from "./useArchiveUpdatePageState";
import InputForm from "../../components/InputForm/InputForm";
import PageBase from "../PageBase/PageBase";
import BackButton from "../../components/BackButton/BackButton";
import TiTleText from "../../components/TiTleText/TiTleText";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialogState from "../../components/ConfirmDialog/useConfirmDialogState";

/**
 * アーカイブ情報の更新ページのレイアウト
 */
const ArchiveUpdatePageLayout = () => {
  const location = useLocation();
  const targetArchiveId = location.state as string;
  const {
    state,
    isLoading,
    onChangeTitle,
    onChangeDescription,
    onClickUploadFile,
    onClickArchiveMovie,
    onClickUpdate,
    onClickDelete,
  } = useArchiveUpdatePageState(targetArchiveId);
  const { openDialog, closeDialog } = useConfirmDialogState();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      onClickArchiveMovie(file);
    },
    [onClickArchiveMovie]
  );

  const { getRootProps } = useDropzone({
    onDrop,
  });

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files == null) {
      return;
    }
    const file = event.target.files[0];
    onClickArchiveMovie(file);
  };

  const archiveMovieFileName: string = useMemo(() => {
    if (state.archiveMovieFile !== null) {
      return state.archiveMovieFile.name;
    }
    if (state.archiveMovieFileUrl !== "") {
      return state.archiveMovieFileUrl;
    }
    return "ファイルを選択";
  }, [state.archiveMovieFile, state.archiveMovieFileUrl]);

  return (
    <PageBase row={12}>
      <Container maxWidth="xs">
        <Stack
          paddingY={6}
          direction="column"
          justifyContent="center"
          spacing={4}
        >
          <BackButton />
          <TiTleText
            title="アーカイブの更新"
            subTitle={`アーカイブ情報の更新を行います。\n各項目を入力してください。`}
          />
          <Box />
          <Stack
            direction="column"
            justifyContent="start"
            alignItems="start"
            spacing={0}
          >
            <FormControl fullWidth>
              <Typography variant="subtitle1" textAlign="start">
                アーカイブ動画
                <span
                  style={{
                    color: "red",
                    paddingLeft: "8px",
                  }}
                >
                  必須
                </span>
              </Typography>
              <Container
                sx={{
                  width: "100%",
                  borderRadius: "4px",
                  borderColor: "#8C8C8C",
                  background: "#D3D3D3",
                  border: 1,
                  p: "8px",
                }}
              >
                <div
                  /* eslint-disable react/jsx-props-no-spreading */
                  {...getRootProps()}
                >
                  <Typography component="div" variant="body1">
                    ここにドラッグ＆ドロップしてね
                  </Typography>
                  <Box my={1} />
                  <Typography component="div" variant="body1">
                    または
                  </Typography>
                  <Box my={1} />
                  <Container
                    sx={{
                      width: "90%",
                      background: "#FFFFFF",
                      p: "8px",
                    }}
                  >
                    <Typography component="div" variant="subtitle1">
                      {archiveMovieFileName}
                      <input
                        hidden
                        type="file"
                        accept=".mp4"
                        onChange={onFileInputChange}
                      />
                    </Typography>
                  </Container>
                </div>
              </Container>
              <FormHelperText
                component="div"
                error={!!state.errors?.archiveMovie}
              >
                <Typography
                  variant="caption"
                  style={{ color: state.errors?.archiveMovie ? "red" : "grey" }}
                >
                  {state.errors?.archiveMovie}
                </Typography>
              </FormHelperText>
            </FormControl>
          </Stack>
          <Stack
            sx={{ mt: "16px" }}
            direction="row"
            justifyContent="start"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              variant="rounded"
              sx={{
                bgcolor: "grey",
                width: 256,
                height: 156,
                margin: "auto",
              }}
              alt={state.title ?? "Thumnail"}
              src={
                state.uploadFile != null
                  ? window.URL.createObjectURL(state.uploadFile)
                  : state.thumbnailUrl
              }
            >
              No Image
            </Avatar>
            <Box>
              <Button variant="outlined" component="label">
                サムネイル画像の
                <br />
                アップロード
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={onClickUploadFile}
                />
              </Button>
            </Box>
          </Stack>
          <InputForm
            label="アーカイブタイトル"
            isLabelRequired
            maxLines={1}
            value={state.title}
            onChange={onChangeTitle}
            error={state.errors?.title}
          />
          <InputForm
            label="備考"
            multiline
            minLines={3}
            value={state.description}
            onChange={onChangeDescription}
          />
          <Box />
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              borderRadius: "4px",
              elevation: 3,
              py: "16px",
              fontSize: 16,
            }}
            loadingIndicator="読込中..."
            loading={isLoading}
            disabled={isLoading}
            onClick={onClickUpdate}
          >
            <Typography color="#ffffff">この内容で更新する</Typography>
          </LoadingButton>

          <Typography
            variant="subtitle1"
            color="secondary"
            onClick={openDialog}
          >
            <span style={{ cursor: "pointer", borderBottom: "solid 1px" }}>
              アーカイブ情報を削除する
            </span>
          </Typography>
        </Stack>
      </Container>
      <ConfirmDialog
        title="アーカイブ情報を削除"
        description="アーカイブ情報を本当に削除してもよろしいでしょうか？"
        onClickExecute={() => {
          onClickDelete();
          closeDialog();
        }}
      />
    </PageBase>
  );
};

export default ArchiveUpdatePageLayout;
