import {
  updateDoc,
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  QuerySnapshot,
  onSnapshot,
  addDoc,
  where,
  getDocs,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { ISchool, schoolConverter } from "../interfaces/ISchool";

/**
 * 新規の学校データの作成する。
 */
export const createSchool = async (school: ISchool) => {
  const docRef = collection(firestore, "schools").withConverter(
    schoolConverter
  );
  return addDoc(docRef, school);
};

/**
 * 指定したSchool IDの学校情報を更新する。
 */
export const updateSchool = async (schoolId: string, school: ISchool) => {
  const docRef = doc(firestore, "schools", schoolId).withConverter(
    schoolConverter
  );
  return updateDoc(docRef, school);
};

/**
 * 指定したSchool IDの学校情報を取得する。
 */
export const getSchoolById = async (
  schoolId: string
): Promise<ISchool | null> => {
  if (schoolId === "") {
    return null;
  }
  const docRef = doc(firestore, "schools", schoolId).withConverter(
    schoolConverter
  );
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists()) {
    return null;
  }
  return snapshot.data();
};

/**
 * 指定したSchool IDの学校情報を論理削除する。
 */
export const deleteSchool = async (schoolId: string) => {
  const school = await getSchoolById(schoolId);
  if (school == null) {
    return null;
  }
  school.isDeleted = true;
  const docRef = doc(firestore, "schools", schoolId).withConverter(
    schoolConverter
  );
  return updateDoc(docRef, school);
};

/**
 * 指定した代理店ユーザーIDの担当の学校一覧を取得する。
 */
export const getSchoolsByAgentUserId = async (
  agentUserId: string
): Promise<ISchool[]> => {
  if (agentUserId === "") {
    return [];
  }
  const schoolsCol = collection(firestore, "schools").withConverter(
    schoolConverter
  );
  const q = query(
    schoolsCol,
    where("isDeleted", "==", false),
    where("agentUserId", "==", agentUserId),
    orderBy("createdAt")
  );
  const snapshot = await getDocs(q);
  const schools: ISchool[] = [];
  snapshot.docs.forEach((itemDoc) => {
    const item = itemDoc.data();
    schools.push(item);
  });
  return schools;
};

/**
 * すべての学校情報をStreamに取得する。
 */
export const onSnapshotAllSchools = (
  onChange: (snapshot: QuerySnapshot<ISchool>) => void
) => {
  const schoolsCol = collection(firestore, "schools").withConverter(
    schoolConverter
  );
  const q = query(
    schoolsCol,
    where("isDeleted", "==", false),
    orderBy("createdAt")
  );
  const sub = onSnapshot(q, (snapshot) => {
    onChange(snapshot);
  });
  return sub;
};

/**
 * 代理店ユーザーの担当の学校情報をStreamに取得する。
 */
export const onSnapshotSchoolsByAgentUserId = (
  onChange: (snapshot: QuerySnapshot<ISchool>) => void,
  agentUserId: string
) => {
  const schoolsCol = collection(firestore, "schools").withConverter(
    schoolConverter
  );
  const q = query(
    schoolsCol,
    where("isDeleted", "==", false),
    where("agentUserId", "==", agentUserId),
    orderBy("createdAt")
  );
  const sub = onSnapshot(q, (snapshot) => {
    onChange(snapshot);
  });
  return sub;
};
