import { RecoilRoot } from "recoil";
import { ErrorBoundary } from "react-error-boundary";
import { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import ReactDOM from "react-dom/client";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@emotion/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import themes from "./layout/themes";
import ResultAlert from "./components/ResultAlert/ResultAlert";
import ErrorPage from "./features/ErrorPage/ErrorPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: 0,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={themes}>
      <RecoilRoot>
        <ErrorBoundary fallback={<ErrorPage />}>
          <Suspense fallback={<CircularProgress />}>
            <App />
          </Suspense>
        </ErrorBoundary>
        <ResultAlert />
      </RecoilRoot>
    </ThemeProvider>
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
