import { useMemo } from "react";
import { Container, Stack, Typography, Grid, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useNavigate } from "react-router";
import AgoraUIKit from "agora-react-uikit";
import PageBase from "../PageBase/PageBase";
import useLiveDetailPageState from "./LiveDetailPageState";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialogState from "../../components/ConfirmDialog/useConfirmDialogState";

/**
 * ライブ一覧表示のページ
 */
const LiveDetailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const targetLiveId = location.state as string;
  const {
    state,
    isLoadingGetLive,
    isLoading,
    onClickLiveStart,
    agoraParams,
    agoraCallbacks,
    onClickLiveStop,
  } = useLiveDetailPageState(targetLiveId);
  const { openDialog, closeDialog } = useConfirmDialogState();

  const buttonText = useMemo(() => {
    if (state.live?.isFinished) {
      return "ライブ配信済み";
    }
    if (agoraParams == null) {
      return "ライブ配信開始";
    }
    return "ライブ配信停止";
  }, [state.live, agoraParams]);

  return (
    <PageBase row={12}>
      <Container maxWidth="md">
        {isLoadingGetLive ? (
          <Typography>Loading...</Typography>
        ) : (
          <Stack
            paddingY={6}
            direction="column"
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5"> {state.live?.title ?? ""}</Typography>
            <Box my={1} />
            <Typography variant="caption">
              {state.live?.description ?? ""}
            </Typography>
            <Box my={2} />
            <Grid item xs={12} my={0}>
              <LoadingButton
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "4px",
                  elevation: 3,
                  px: "24px",
                  py: "8px",
                  fontSize: 16,
                }}
                loadingIndicator="読込中..."
                loading={isLoading}
                disabled={
                  state.live == null || state.live?.isFinished || isLoading
                }
                onClick={openDialog}
              >
                <Typography variant="caption" sx={{ color: "#FFFFFF" }}>
                  {buttonText}
                </Typography>
              </LoadingButton>
              <Box my={2} />
              {agoraParams != null ? (
                <Box
                  sx={{
                    display: "flex",
                    width: {
                      lg: "960px",
                      sm: "720px",
                      xs: "480px",
                    },
                    height: {
                      lg: "540px",
                      sm: "405px",
                      xs: "270px",
                    },
                  }}
                >
                  <AgoraUIKit
                    rtcProps={agoraParams}
                    callbacks={agoraCallbacks}
                  />
                </Box>
              ) : (
                <Typography>
                  {!state.live?.isFinished
                    ? "配信していません。"
                    : "配信を終了しました。"}
                </Typography>
              )}
            </Grid>
          </Stack>
        )}
      </Container>
      <ConfirmDialog
        title={agoraParams == null ? "ライブ配信開始" : "ライブ配信停止"}
        description={
          agoraParams == null
            ? "ライブ配信を開始してよろしいでしょうか？"
            : "ライブ配信を停止してよろしいでしょうか？"
        }
        onClickExecute={() => {
          if (agoraParams == null) {
            onClickLiveStart();
          } else {
            onClickLiveStop();
          }
          closeDialog();
        }}
      />
    </PageBase>
  );
};

export default LiveDetailPage;
