import { createTheme } from "@mui/material";

/**
 * テーマカラーの設定
 */
export default createTheme({
  palette: {
    // 背景グレー
    background: {
      default: "#EFEFEF",
    },
    // 普通グレー
    primary: {
      main: "#BABABA",
    },
    // 濃グレー
    secondary: {
      main: "#757575",
    },
    // 文字グレー
    info: {
      main: "#E30551",
    },
    // 赤
    error: {
      main: "#ff0000",
    },
  },
  typography: {
    allVariants: {
      color: "#000000",
    },
    fontFamily: ["Inter"].join(","),
    caption: {
      fontSize: "0.9rem",
    },
    body1: {
      fontSize: "1.1rem",
    },
    body2: {
      fontSize: "0.95rem",
    },
  },
});
