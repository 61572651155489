import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Typography,
  AppBar,
  Stack,
  Card,
  CircularProgress,
  Box,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useAuthSignOut } from "@react-query-firebase/auth";
import { useMutation, useQuery } from "react-query";
import LoadingButton from "@mui/lab/LoadingButton";
import { useCallback } from "react";
import { auth } from "../../firebase";
import { getAgentUserById } from "../../repositories/agentUserRepository";
import authGuardStateAtom from "../../recoil/AuthGuard/atom";
import { getAdminUserById } from "../../repositories/adminUserRepository";
import { loginPagePath } from "../../layout/urls";

/**
 * ページ全般のアプリバー
 */
const CustomAppBar = () => {
  const navigate = useNavigate();
  const authState = useRecoilValue(authGuardStateAtom);
  const resetAuthState = useResetRecoilState(authGuardStateAtom);

  /** 認証済み代理店ユーザー情報取得 */
  const { data: name, isLoading } = useQuery(
    ["getUserName", authState.userId],
    async () => {
      if (authState.userId === "") {
        return null;
      }
      const agentUser = await getAgentUserById(authState.userId);
      if (agentUser) {
        return agentUser.name;
      }
      const adminUser = await getAdminUserById(authState.userId);
      if (adminUser) {
        return adminUser.name;
      }
      return null;
    }
  );

  // FirebaseAuthログアウト処理Mutation
  const authLogoutMutation = useAuthSignOut(auth);

  // ログアウト処理
  const signOutMutation = useMutation(async () => {
    await authLogoutMutation.mutateAsync();
    resetAuthState();
    navigate(loginPagePath);
  });

  /**
   * ログアウトボタンクリック時
   */
  const onClickLogOut = useCallback(() => {
    void signOutMutation.mutate();
  }, [signOutMutation]);

  if (isLoading || !name) {
    return <CircularProgress />;
  }
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: "#FFFFFF",
        height: 60,
      }}
      elevation={0}
    >
      <Card sx={{ width: "100%", py: 1 }}>
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          spacing={0}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              paddingLeft: "16px",
              display: {
                xs: "none",
                sm: "block",
              },
            }}
          >
            HUGTV管理画面
          </Typography>
          <Stack
            direction="row"
            spacing={0.5}
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              direction="row"
              spacing={0.5}
              justifyContent="center"
              alignItems="center"
              paddingRight={2}
            >
              <AccountCircleIcon sx={{ fontSize: 32 }} />
              <Typography variant="subtitle1">{`${name}`}</Typography>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Box />
            <LoadingButton
              color="secondary"
              loading={signOutMutation.isLoading}
              onClick={onClickLogOut}
              loadingIndicator="読込中..."
            >
              <Stack
                direction="row"
                spacing={0.5}
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                >
                  ログアウト
                </Typography>
                <LogoutIcon color="secondary" sx={{ fontSize: 32 }} />
              </Stack>
            </LoadingButton>
          </Stack>
        </Stack>
      </Card>
    </AppBar>
  );
};

export default CustomAppBar;
