import S3 from "aws-sdk/clients/s3";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import { s3Bucket, s3Credentials, s3Region } from "../s3";

/**
 * アーカイブ動画のsrcを取得する。
 */
export const getArchiveMovieSrc = async (s3Path: string) => {
  const s3 = new S3({
    credentials: s3Credentials,
    region: s3Region,
    signatureVersion: "v4",
  });
  const signedUrlExpireSeconds = 60 * 15;
  try {
    const url = await s3.getSignedUrlPromise("getObject", {
      Bucket: s3Bucket,
      Key: s3Path,
      Expires: signedUrlExpireSeconds,
    });
    return url;
  } catch (e) {
    return "";
  }
};

/**
 * アーカイブ動画のファイルをアップロードする。
 */
export const uploadArchiveMovie = async (
  archiveId: string,
  file: File
): Promise<string | null> => {
  const fileExtension = file.name.split(".").pop() ?? "mp4";
  const s3Path = `${archiveId}/archive_movie.${fileExtension}`;
  try {
    const parallelUploads3 = new Upload({
      client: new S3Client({
        region: s3Region,
        credentials: s3Credentials,
      }),
      params: {
        Bucket: s3Bucket,
        Key: s3Path,
        Body: file,
      },
      leavePartsOnError: false,
    });
    parallelUploads3.on("httpUploadProgress", (progress) => {
      console.log(progress);
    });
    await parallelUploads3.done();
    return s3Path;
  } catch (e) {
    return null;
  }
};
