import { Container, Stack, Typography, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useAgentUserRegisterPageState from "./useAgentUserRegisterPageState";
import InputForm from "../../components/InputForm/InputForm";
import PageBase from "../PageBase/PageBase";
import BackButton from "../../components/BackButton/BackButton";
import TiTleText from "../../components/TiTleText/TiTleText";

/**
 * 代理店ユーザー登録ページ
 */
const AgentUserRegisterPage = () => {
  const {
    state,
    isLoading,
    onChangeEmail,
    onChangePassword,
    onChangeName,
    onClickRegister,
  } = useAgentUserRegisterPageState();

  return (
    <PageBase row={12}>
      <Container maxWidth="xs">
        <Stack
          paddingY={6}
          direction="column"
          justifyContent="center"
          spacing={4}
        >
          <BackButton />
          <TiTleText
            title="代理店ユーザー登録"
            subTitle={`代理店ユーザーの登録を行います。\n※パスワードは初期設定として「password」が設定されるようになります。`}
          />
          <Box />
          <InputForm
            label="メールアドレス"
            isLabelRequired
            type="email"
            maxLines={1}
            value={state.email}
            onChange={onChangeEmail}
            error={state.errors?.email}
          />
          <InputForm
            label="パスワード"
            isLabelRequired
            required
            value={state.password}
            onChange={onChangePassword}
            error={state.errors?.password}
            disabled
          />
          <InputForm
            label="代理店名"
            isLabelRequired
            required
            maxLines={1}
            value={state.name}
            onChange={onChangeName}
            error={state.errors?.name}
          />
          <Box />
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              borderRadius: "4px",
              elevation: 3,
              py: "16px",
              fontSize: 16,
            }}
            loadingIndicator="読込中..."
            loading={isLoading}
            disabled={isLoading}
            onClick={onClickRegister}
          >
            <Typography color="#ffffff">この内容で登録する</Typography>
          </LoadingButton>
        </Stack>
      </Container>
    </PageBase>
  );
};

export default AgentUserRegisterPage;
