import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import {
  validateEmail,
  validatePassword,
  validateRequired,
} from "../utils/validator";

/**
 * 代理店ユーザー情報を表現するインターフェース
 */
export interface IAgentUser {
  id: string;
  name: string;
  isDeleted: boolean;
  createdAt: number;
}

/**
 * 代理店ユーザー情報のバリデーション。
 */
export type AgentUserErrors = {
  email: string | undefined;
  password: string | undefined;
  name: string | undefined;
};
export const validateAgentUser = (
  email: string,
  password: string,
  name: string
): AgentUserErrors | undefined => {
  const errors: AgentUserErrors = {
    email: undefined,
    password: undefined,
    name: undefined,
  };
  errors.email = validateEmail(email);
  errors.password = validatePassword(password);
  errors.name = validateRequired(name);
  if (
    Object.values(errors).findIndex(
      (element) => typeof element === "string"
    ) !== -1
  ) {
    return errors;
  }
  return undefined;
};

/**
 * Firestoreからの情報取得用、データコンバータ。
 * Firestoreからのsnapshotと、代理店ユーザー情報の型を変換する。
 */
export const agentUserConverter: FirestoreDataConverter<IAgentUser> = {
  toFirestore: (agentUser: IAgentUser): DocumentData => ({
    id: agentUser.id,
    name: agentUser.name,
    isDeleted: agentUser.isDeleted,
    createdAt: agentUser.createdAt,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): IAgentUser => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name as string,
      isDeleted: data.isDeleted as boolean,
      createdAt: data.createdAt as number,
    } as IAgentUser;
  },
};
