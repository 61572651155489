import { FC } from "react";
import { Typography, Stack } from "@mui/material";

export type TiTleTextProps = {
  /** タイトルテキスト */
  title: string;
  /** サブタイトルテキスト */
  subTitle: string;
};

/**
 * タイトルとサブタイトル表示のテキスト
 */
const TiTleText: FC<TiTleTextProps> = ({ title, subTitle }) => (
  <Stack
    direction="column"
    spacing={1}
    alignItems="center"
    justifyContent="center"
  >
    <Typography variant="h4" sx={{ fontSize: { xs: "24px", sm: "30px" } }}>
      {title}
    </Typography>
    <Typography variant="subtitle1" sx={{ whiteSpace: "pre-wrap" }}>
      {subTitle}
    </Typography>
  </Stack>
);

export default TiTleText;
