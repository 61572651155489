import { atom } from "recoil";
import { LiveErrors } from "../../interfaces/ILive";

export type LiveUpdatePageState = {
  /** ライブタイトル */
  title: string;
  /** 備考欄 */
  description: string;
  /** ライブ開始日時 */
  liveStartAt: number;
  /** ライブ終了日時 */
  liveEndAt: number;
  /** サムネイル画像URL */
  thumbnailUrl: string;
  /** サムネイル画像ファイル */
  uploadFile: File | null;
  /** バリデーションエラー */
  errors: LiveErrors | undefined;
};

const initialState: LiveUpdatePageState = {
  title: "",
  description: "",
  liveStartAt: new Date().setMinutes(new Date().getMinutes() - 30),
  liveEndAt: new Date().setMinutes(new Date().getMinutes() + 30),
  thumbnailUrl: "",
  uploadFile: null,
  errors: undefined,
};

const liveUpdatePageStateAtom = atom({
  key: "LiveUpdatePageState",
  default: initialState,
});

export default liveUpdatePageStateAtom;
