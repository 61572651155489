import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Container, Stack, Typography, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useAgentUserUpdatePageState from "./useAgentUserUpdatePageState";
import InputForm from "../../components/InputForm/InputForm";
import PageBase from "../PageBase/PageBase";
import BackButton from "../../components/BackButton/BackButton";
import TiTleText from "../../components/TiTleText/TiTleText";
import authGuardStateAtom from "../../recoil/AuthGuard/atom";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialogState from "../../components/ConfirmDialog/useConfirmDialogState";

/**
 * 代理店ユーザー更新ページのレイアウト
 */
const AgentUserUpdatePageLayout = () => {
  const location = useLocation();
  const targetAgentUserId = location.state as string;
  const { state, isLoading, onChangeName, onClickUpdate, onClickDelete } =
    useAgentUserUpdatePageState(targetAgentUserId);
  const authState = useRecoilValue(authGuardStateAtom);
  const { openDialog, closeDialog } = useConfirmDialogState();

  return (
    <PageBase row={12}>
      <Container maxWidth="xs">
        <Stack
          paddingY={6}
          direction="column"
          justifyContent="center"
          spacing={4}
        >
          <BackButton />
          <TiTleText
            title="代理店ユーザー更新"
            subTitle={`代理店ユーザーの更新を行います。\n各項目を入力してください。`}
          />
          <Box />
          <InputForm
            label="代理店名"
            isLabelRequired
            maxLines={1}
            value={state.name}
            onChange={onChangeName}
            error={state.nameError}
          />
          <Box />
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              borderRadius: "4px",
              elevation: 3,
              py: "16px",
              fontSize: 16,
            }}
            loadingIndicator="読込中..."
            loading={isLoading}
            disabled={isLoading}
            onClick={onClickUpdate}
          >
            <Typography color="#ffffff">この内容で更新する</Typography>
          </LoadingButton>
          {authState.userType === "admin" ? (
            <Typography
              variant="subtitle1"
              color="secondary"
              onClick={openDialog}
            >
              <span style={{ cursor: "pointer", borderBottom: "solid 1px" }}>
                代理店ユーザーを削除する
              </span>
            </Typography>
          ) : null}
        </Stack>
      </Container>
      <ConfirmDialog
        title="代理店ユーザーを削除"
        description="代理店ユーザーを本当に削除してもよろしいでしょうか？"
        onClickExecute={() => {
          onClickDelete();
          closeDialog();
        }}
      />
    </PageBase>
  );
};

export default AgentUserUpdatePageLayout;
