import { FirebaseError } from "firebase/app";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useAuthSendPasswordResetEmail } from "@react-query-firebase/auth";
import { useCallback, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import resetPasswordPageStateAtom from "../../recoil/ResetPasswordPage/atom";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import { validateEmail } from "../../utils/validator";
import { auth } from "../../firebase";
import firebaseError2ErrorMessage from "../../utils/converter";

/**
 * パスワードリセットページの状態管理やロジックをまとめたHooks
 */
const useResetPasswordPageState = () => {
  const [state, setState] = useRecoilState(resetPasswordPageStateAtom);
  const resetState = useResetRecoilState(resetPasswordPageStateAtom);
  const resetPasswordMutation = useAuthSendPasswordResetEmail(auth);
  const { openAlert } = useResultAlertState();

  useEffect(() => {
    resetState();
  }, [resetState]);

  /**
   * メールアドレスの変更を状態に反映させる。
   */
  const onChangeEmail = useCallback(
    (email: string) => {
      setState((prev) => ({ ...prev, email }));
    },
    [setState]
  );

  /**
   * パスワードリセットに関するメール送信の処理
   */
  const sendResetPasswordEmail = useCallback(async () => {
    const emailValidateError = validateEmail(state.email);
    setState((prev) => ({ ...prev, emailValidateError }));
    if (emailValidateError) {
      openAlert("error", "パスワードリセットのメール送信に失敗しました。");
      return;
    }
    try {
      await resetPasswordMutation.mutateAsync({
        email: state.email,
      });
      openAlert(
        "success",
        "パスワードリセットに関するメールが送信されました。"
      );
    } catch (err) {
      if (err instanceof FirebaseError) {
        const message = firebaseError2ErrorMessage(err);
        openAlert("error", message);
        return;
      }
      openAlert("error", "パスワードリセットのメール送信に失敗しました。");
    }
  }, [openAlert, resetPasswordMutation, setState, state.email]);

  const {
    isLoading: isLoadingSendResetPasswordEmail,
    isRefetching: isRefetchingSendResetPasswordEmail,
    refetch,
  } = useQuery("sendResetPasswordEmail", () => sendResetPasswordEmail(), {
    enabled: false,
    suspense: false,
  });

  /**
   * 送信するボタンをクリック時の処理
   */
  const onClickSendResetPasswordEmail = useCallback(() => {
    void refetch();
  }, [refetch]);

  const isLoading = useMemo(
    () => isLoadingSendResetPasswordEmail || isRefetchingSendResetPasswordEmail,
    [isLoadingSendResetPasswordEmail, isRefetchingSendResetPasswordEmail]
  );

  return { state, isLoading, onChangeEmail, onClickSendResetPasswordEmail };
};

export default useResetPasswordPageState;
