import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { fireStorage } from "../firebase";

/**
 * サムネイル画像のsrcを取得する。
 */
export const getThumnailSrc = async (storagePath: string) => {
  if (storagePath === "") {
    return null;
  }
  const reference = ref(fireStorage, storagePath);
  try {
    return await getDownloadURL(reference);
  } catch (e) {
    return null;
  }
};

/**
 * サムネイル画像をアップロードする。
 */
export const uploadThumnail = async (
  directory: string,
  id: string,
  avatar: File
): Promise<string | null> => {
  const reference = ref(fireStorage, `${directory}/${id}/thumnail.png`);
  try {
    const snapshot = await uploadBytes(reference, avatar);
    return snapshot.ref.fullPath;
  } catch (e) {
    return null;
  }
};

/**
 * サムネイル画像を削除する。
 */
export const deleteThumnail = async (directory: string, id: string) => {
  const reference = ref(fireStorage, `${directory}/${id}/thumnail.png`);
  try {
    return await deleteObject(reference);
  } catch (e) {
    return null;
  }
};
