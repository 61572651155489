import { atom } from "recoil";
import { SchoolErrors } from "../../interfaces/ISchool";

export type SchoolUpdatePageState = {
  /** 学校名 */
  name: string;
  /** コード */
  code: string;
  /** 備考欄 */
  description: string;
  /** サムネイル画像URL */
  thumbnailUrl: string;
  /** サムネイル画像ファイル */
  uploadFile: File | null;
  /** バリデーションエラー */
  errors: SchoolErrors | undefined;
};

const initialState: SchoolUpdatePageState = {
  name: "",
  code: "",
  description: "",
  thumbnailUrl: "",
  uploadFile: null,
  errors: undefined,
};

const schoolUpdatePageStateAtom = atom({
  key: "SchoolUpdatePageState",
  default: initialState,
});

export default schoolUpdatePageStateAtom;
