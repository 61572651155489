import { useRecoilState, useResetRecoilState } from "recoil";
import { ChangeEvent, useCallback, useEffect } from "react";
import { useMutation } from "react-query";
import useResultAlertState from "../../components/ResultAlert/useResultAlertState";
import archiveRegisterPageStateAtom from "../../recoil/ArchiveRegisterPage/atom";
import { IArchive, validateArchive } from "../../interfaces/IArchive";
import {
  createArchive,
  getArchiveById,
  updateArchive,
} from "../../repositories/archiveRepository";
import { auth } from "../../firebase";
import {
  getThumnailSrc,
  uploadThumnail,
} from "../../repositories/storageRepository";
import { uploadArchiveMovie } from "../../repositories/s3Repository";
import { isMovieJudge } from "../../utils/utilities";

/**
 * アーカイブ情報の登録ページの状態管理やロジックをまとめたHooks
 */
const useArchiveRegisterPageState = () => {
  const [state, setState] = useRecoilState(archiveRegisterPageStateAtom);
  const resetState = useResetRecoilState(archiveRegisterPageStateAtom);
  const { openAlert } = useResultAlertState();

  useEffect(() => {
    resetState();
  }, [resetState]);

  // InputFormの入力値の状態をonChangeで保持
  /**
   * アーカイブタイトルの変更を状態に反映させる。
   */
  const onChangeTitle = useCallback(
    (title: string) => {
      setState((prev) => ({ ...prev, title }));
    },
    [setState]
  );
  /**
   * その他備考欄の内容を状態に反映させる。
   */
  const onChangeDescription = useCallback(
    (description: string) => {
      setState((prev) => ({ ...prev, description }));
    },
    [setState]
  );
  /**
   * サムネイル画像のアップロードを状態に反映させる。
   */
  const onClickUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0] ?? null;
    setState((prev) => ({ ...prev, uploadFile: file }));
  };
  /**
   * 学校選択の変更を状態に反映させる。
   */
  const onChangeSchool = useCallback(
    (schoolId: string) => {
      setState((prev) => ({ ...prev, schoolId }));
    },
    [setState]
  );
  /**
   * アーカイブ動画のアップロードを状態に反映させる。
   */
  const onClickArchiveMovie = (archiveMovieFile: File) => {
    if (!isMovieJudge(archiveMovieFile)) {
      openAlert("error", "動画ファイルをアップロードしてください。");
      setState((prev) => ({ ...prev, archiveMovieFile: null }));
      return;
    }
    setState((prev) => ({ ...prev, archiveMovieFile }));
  };

  /**
   * アーカイブ情報の登録する。
   */
  const registerArchive = useCallback(async () => {
    if (auth.currentUser == null) {
      return;
    }
    const errors = validateArchive(
      state.title,
      state.schoolId,
      state.archiveMovieFile?.name ?? ""
    );
    if (errors) {
      setState((prev) => ({ ...prev, errors }));
      throw new Error("入力情報を確認してください。");
    }
    setState((prev) => ({ ...prev, errors: undefined }));

    const archive: IArchive = {
      id: "",
      liveId: "",
      title: state.title,
      thumbnailUrl: "",
      description: state.description,
      schoolId: state.schoolId,
      archiveMovieUrl: "",
      createdAt: new Date().getTime(),
      createdUser: auth.currentUser.uid,
    };
    const createdArchiveDocId = await createArchive(archive, "");
    const createdArchive = await getArchiveById(createdArchiveDocId);
    if (createdArchive != null) {
      const archiveId = createdArchiveDocId;
      if (state.uploadFile != null) {
        const thumnailPath =
          (await uploadThumnail("archives", archiveId, state.uploadFile)) ?? "";
        const thumbnailUrl = (await getThumnailSrc(thumnailPath)) ?? "";
        createdArchive.thumbnailUrl = thumbnailUrl;
      }
      if (state.archiveMovieFile != null) {
        const archiveMoviePath = await uploadArchiveMovie(
          archiveId,
          state.archiveMovieFile
        );
        createdArchive.archiveMovieUrl = archiveMoviePath ?? "";
      }
      await updateArchive(createdArchiveDocId, createdArchive);
    }
  }, [
    state.title,
    state.schoolId,
    state.description,
    state.uploadFile,
    state.archiveMovieFile,
    setState,
  ]);
  const registerMutate = useMutation<void, Error>(() => registerArchive(), {
    onSuccess: () => {
      openAlert("success", "アーカイブ情報の作成に成功しました。");
      resetState();
    },
    onError: (error) => {
      openAlert("error", error.message);
    },
  });

  /**
   * 登録ボタンクリック時の処理
   */
  const onClickRegister = useCallback(() => {
    void registerMutate.mutate();
  }, [registerMutate]);

  return {
    state,
    isLoading: registerMutate.isLoading,
    onChangeTitle,
    onChangeSchool,
    onChangeDescription,
    onClickUploadFile,
    onClickArchiveMovie,
    onClickRegister,
  };
};

export default useArchiveRegisterPageState;
