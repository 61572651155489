import { atom } from "recoil";
import { AgentUserErrors } from "../../interfaces/IAgentUser";

export type AgentUserRegisterPageState = {
  /** メールアドレス */
  email: string;
  /** パスワード */
  password: string;
  /** 代理店名 */
  name: string;
  /** バリデーションエラー */
  errors: AgentUserErrors | undefined;
};

const initialState: AgentUserRegisterPageState = {
  email: "",
  password: "password",
  name: "",
  errors: undefined,
};

const agentUserRegisterPageStateAtom = atom({
  key: "AgentUserRegisterPageState",
  default: initialState,
});

export default agentUserRegisterPageStateAtom;
