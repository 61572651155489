import {
  updateDoc,
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  QuerySnapshot,
  onSnapshot,
  addDoc,
  deleteDoc,
  where,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { IArchive, archiveConverter } from "../interfaces/IArchive";

/**
 * 新規のアーカイブデータの作成する。
 */
export const createArchive = async (archive: IArchive, id: string) => {
  const colRef = collection(firestore, "archives").withConverter(
    archiveConverter
  );
  if (id !== "") {
    await setDoc(doc(colRef, id), archive);
    return id;
  }
  const res = await addDoc(colRef, archive);
  return res.id;
};

/**
 * 指定したArchive IDのアーカイブ情報を更新する。
 */
export const updateArchive = async (archiveId: string, archive: IArchive) => {
  const docRef = doc(firestore, "archives", archiveId).withConverter(
    archiveConverter
  );
  return updateDoc(docRef, archive);
};

/**
 * 指定したArchive IDのアーカイブ情報を取得する。
 */
export const getArchiveById = async (
  archiveId: string
): Promise<IArchive | null> => {
  if (archiveId === "") {
    return null;
  }
  const docRef = doc(firestore, "archives", archiveId).withConverter(
    archiveConverter
  );
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists()) {
    return null;
  }
  return snapshot.data();
};

/**
 * 指定したArchive IDのアーカイブ情報を物理削除する。
 */
export const deleteArchive = async (archiveId: string) => {
  const docRef = doc(firestore, "archives", archiveId);
  return deleteDoc(docRef);
};

/**
 * 作成者ユーザーIDに該当するアーカイブ情報を取得する。
 */
export const getArchivesByCreatedUser = async (
  createdUser: string
): Promise<IArchive[]> => {
  if (createdUser === "") {
    return [];
  }
  const archivesCol = collection(firestore, "archives").withConverter(
    archiveConverter
  );
  const q = query(
    archivesCol,
    where("createdUser", "==", createdUser),
    orderBy("createdAt")
  );
  const snapshot = await getDocs(q);
  const archives: IArchive[] = [];
  snapshot.docs.forEach((itemDoc) => {
    const item = itemDoc.data();
    archives.push(item);
  });
  return archives;
};

/**
 * 作成者ユーザーIDに該当するアーカイブ情報をStreamに取得する。
 */
export const onSnapshotArchivesByCreatedUser = (
  onChange: (snapshot: QuerySnapshot<IArchive>) => void,
  createdUser: string
) => {
  const archivesCol = collection(firestore, "archives").withConverter(
    archiveConverter
  );
  const q = query(
    archivesCol,
    where("createdUser", "==", createdUser),
    orderBy("createdAt")
  );
  const sub = onSnapshot(q, (snapshot) => {
    onChange(snapshot);
  });
  return sub;
};
