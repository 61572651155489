import { Box, Container, Stack, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useResetPasswordPageState from "./useResetPasswordPageState";
import InputForm from "../../components/InputForm/InputForm";
import BackButton from "../../components/BackButton/BackButton";
import TiTleText from "../../components/TiTleText/TiTleText";
import NotHeaderPageBase from "../PageBase/NotHeaderPageBase";

/**
 * パスワードリセットページ
 */
const ResetPasswordPage = () => {
  const { state, isLoading, onChangeEmail, onClickSendResetPasswordEmail } =
    useResetPasswordPageState();
  return (
    <NotHeaderPageBase row={12}>
      <Container maxWidth="xs">
        <Stack
          direction="column"
          style={{ height: "100vh" }}
          justifyContent="center"
          spacing={4}
        >
          <BackButton />
          <TiTleText
            title="パスワードリセット"
            subTitle={`アカウントのメールアドレスを入力してください。\nそのアドレス宛にパスワードリセットを行う為のメールを送付します。`}
          />
          <Box />
          <InputForm
            label="メールアドレス"
            required
            isLabelRequired
            type="email"
            onChange={onChangeEmail}
            error={state.emailValidateError}
          />
          <Box />
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              borderRadius: "4px",
              elevation: 3,
              py: "16px",
              fontSize: 16,
            }}
            loadingIndicator="読込中..."
            loading={isLoading}
            disabled={isLoading}
            onClick={onClickSendResetPasswordEmail}
          >
            <Typography color="#ffffff">送信する</Typography>
          </LoadingButton>
        </Stack>
      </Container>
    </NotHeaderPageBase>
  );
};

export default ResetPasswordPage;
