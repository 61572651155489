import {
  Container,
  Stack,
  Typography,
  Grid,
  Button,
  Box,
  OutlinedInput,
  Avatar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import PageBase from "../PageBase/PageBase";
import {
  archiveDetailPagePath,
  archiveRegisterPagePath,
  archiveUpdatePagePath,
} from "../../layout/urls";
import { onSnapshotArchivesByCreatedUser } from "../../repositories/archiveRepository";
import { IArchive } from "../../interfaces/IArchive";
import { auth } from "../../firebase";

/**
 * アーカイブ一覧表示のページ
 */
const ArchiveListPage = () => {
  const navigate = useNavigate();
  const [fetchedArchiveList, setFetchedArchiveList] = useState<IArchive[]>([]);
  const [archiveList, setArchiveList] = useState<IArchive[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  /**
   * アーカイブ一覧のリアルタイム取得
   */
  useEffect(() => {
    const sub = onSnapshotArchivesByCreatedUser((snapshot) => {
      if (snapshot.docs.length > 0) {
        const res = snapshot.docs.map((value) => value.data());
        setFetchedArchiveList(res);
        setArchiveList(res);
      } else {
        setFetchedArchiveList([]);
        setArchiveList([]);
      }
    }, auth.currentUser?.uid ?? "");
    return () => {
      sub();
    };
  }, []);

  return (
    <PageBase row={12}>
      <Container>
        <Stack
          paddingY={6}
          direction="column"
          justifyContent="center"
          spacing={4}
        >
          <Typography
            variant="h4"
            sx={{ fontSize: { xs: "24px", sm: "30px" } }}
          >
            アーカイブ一覧
          </Typography>
          <Container sx={{ background: "#D9D9D9", py: 2 }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Box sx={{ px: { xs: 2, sm: 8 } }} />
              <OutlinedInput
                sx={{ background: "#FFFFFF" }}
                fullWidth
                placeholder="タイトルを入力してください"
                id="outlined-adornment-amount"
                value={searchText}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setArchiveList(fetchedArchiveList);
                  }
                  setSearchText(e.target.value);
                }}
              />
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (searchText === "") {
                      setArchiveList(fetchedArchiveList);
                      return;
                    }
                    const filteredList = fetchedArchiveList.filter((e) =>
                      e.title.includes(searchText)
                    );
                    setArchiveList(filteredList);
                  }}
                >
                  <Typography variant="caption" sx={{ color: "#FFFFFF" }}>
                    検索
                  </Typography>
                </Button>
              </Box>
              <Box sx={{ px: { xs: 2, sm: 8 } }} />
            </Stack>
          </Container>
          <Grid container justifyContent="end" alignItems="end">
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  navigate(archiveRegisterPagePath);
                }}
              >
                <Typography variant="caption" sx={{ color: "#FFFFFF" }}>
                  新規追加
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} my={0}>
              {archiveList.length > 0 ? (
                archiveList.map((item) => (
                  <Container
                    key={item.id}
                    sx={{
                      width: "100%",
                      borderRadius: "4px",
                      borderColor: "#8C8C8C",
                      border: 1,
                      p: "8px",
                      my: "8px",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack
                        direction="row"
                        justifyContent="start"
                        alignItems="center"
                        spacing={4}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(archiveDetailPagePath, {
                            state: item.id as string,
                          });
                        }}
                      >
                        <Box>
                          <Avatar
                            variant="rounded"
                            sx={{
                              bgcolor: "grey",
                              width: 96,
                              height: 56,
                            }}
                            alt={item.title}
                            src={item.thumbnailUrl}
                          >
                            No Image
                          </Avatar>
                        </Box>
                        <Stack
                          direction="column"
                          justifyContent="start"
                          alignItems="start"
                        >
                          <Typography
                            variant="body1"
                            sx={{ color: "#8C8C8C", textTransform: "none" }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ color: "#8C8C8C" }}
                          >
                            {item.description}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          navigate(archiveUpdatePagePath, {
                            state: item.id as string,
                          });
                        }}
                      >
                        更新
                      </Button>
                    </Stack>
                  </Container>
                ))
              ) : (
                <div>アーカイブ情報が存在していません。</div>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </PageBase>
  );
};

export default ArchiveListPage;
