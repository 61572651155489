import { Box, Container, Stack, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link } from "react-router-dom";
import useLoginPageState from "./useLoginPageState";
import InputForm from "../../components/InputForm/InputForm";
import { resetPasswordPath } from "../../layout/urls";
import NotHeaderPageBase from "../PageBase/NotHeaderPageBase";

/**
 * ログインページ
 */
const LoginPage = () => {
  const { state, isLoading, onChangeEmail, onChangePassword, onClickLogin } =
    useLoginPageState();
  return (
    <NotHeaderPageBase row={12}>
      <Container maxWidth="xs">
        <Stack
          paddingY={6}
          direction="column"
          sx={{ height: "100vh" }}
          justifyContent="center"
          spacing={4}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", fontSize: { xs: "24px", sm: "30px" } }}
          >
            HugTV
          </Typography>
          <Box />
          <InputForm
            label="ID［メールアドレス］"
            isLabelRequired
            required
            type="email"
            onChange={onChangeEmail}
            error={state.errors?.email}
          />
          <InputForm
            label="パスワード"
            isLabelRequired
            required
            password
            onChange={onChangePassword}
            error={state.errors?.password}
          />
          <Box />
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              borderRadius: "4px",
              elevation: 3,
              py: "16px",
              fontSize: 16,
            }}
            loadingIndicator="読込中..."
            loading={isLoading}
            onClick={onClickLogin}
          >
            <Typography color="#ffffff">ログイン</Typography>
          </LoadingButton>
          <Typography
            component={Link}
            to={resetPasswordPath}
            variant="body2"
            color="secondary"
          >
            パスワードをお忘れの場合
          </Typography>
        </Stack>
      </Container>
    </NotHeaderPageBase>
  );
};

export default LoginPage;
