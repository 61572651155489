import { useLocation } from "react-router-dom";
import {
  Container,
  Stack,
  Box,
  Tab,
  Grid,
  Avatar,
  Button,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import useAgentUserDetailPageState from "./useAgentUserDetailPageState";
import PageBase from "../PageBase/PageBase";
import BackButton from "../../components/BackButton/BackButton";
import { date2String } from "../../utils/converter";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import useConfirmDialogState from "../../components/ConfirmDialog/useConfirmDialogState";

/**
 * 代理店ユーザーの詳細ページのレイアウト
 */
const AgentUserDetailPageLayout = () => {
  const location = useLocation();
  const targetAgentUserId = location.state as string;
  const {
    state,
    onRefresh,
    onClickLiveDelete,
    onClickArchiveDelete,
    onClickSchoolDelete,
  } = useAgentUserDetailPageState(targetAgentUserId);
  const [currentTab, setCurrentTab] = useState<string>("lives");
  const { openDialog, closeDialog } = useConfirmDialogState();

  return (
    <PageBase row={12}>
      <Container>
        <Stack
          paddingY={6}
          direction="column"
          justifyContent="center"
          spacing={4}
        >
          <BackButton />
          <Stack
            direction="row"
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="h4"
              sx={{ fontSize: { xs: "24px", sm: "30px" } }}
            >
              {state.agentUser?.name}
            </Typography>
            <Button color="secondary" onClick={() => onRefresh()}>
              <Stack
                direction="row"
                spacing={0.5}
                justifyContent="center"
                alignItems="center"
              >
                <RefreshIcon color="secondary" sx={{ fontSize: 32 }} />
                <Typography color="secondary" variant="subtitle1">
                  リロード
                </Typography>
              </Stack>
            </Button>
          </Stack>
          <Box />
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={currentTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={(_: React.SyntheticEvent, value: string) =>
                    setCurrentTab(value)
                  }
                  aria-label="lab API tabs example"
                >
                  <Tab
                    sx={{ width: "33%", typography: "h6" }}
                    label={`ライブ一覧 ${state.lives.length}件`}
                    value="lives"
                  />
                  <Tab
                    sx={{ width: "33%", typography: "h6" }}
                    label={`アーカイブ一覧 ${state.archives.length}件`}
                    value="archives"
                  />
                  <Tab
                    sx={{ width: "33%", typography: "h6" }}
                    label={`学校一覧 ${state.schools.length}件`}
                    value="schools"
                  />
                </TabList>
              </Box>
              <TabPanel value="lives">
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12} my={0}>
                    {state.lives.length > 0 ? (
                      state.lives.map((item) => (
                        <Container
                          key={item.id}
                          sx={{
                            width: "100%",
                            borderRadius: "4px",
                            borderColor: "#8C8C8C",
                            background: item.isFinished ? "#D3D3D3" : "#FFFFFF",
                            border: 1,
                            p: "8px",
                            my: "8px",
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              direction="row"
                              justifyContent="start"
                              alignItems="center"
                              spacing={4}
                            >
                              <Box>
                                <Avatar
                                  variant="rounded"
                                  sx={{
                                    bgcolor: "grey",
                                    width: 96,
                                    height: 56,
                                  }}
                                  alt={item.title}
                                  src={item.thumbnailUrl}
                                >
                                  No Image
                                </Avatar>
                              </Box>
                              <Stack
                                direction="column"
                                justifyContent="start"
                                alignItems="start"
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ color: "#8C8C8C" }}
                                >
                                  {item.title}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{ color: "#8C8C8C" }}
                                >
                                  {date2String(new Date(item.liveStartAt))}~
                                  {date2String(new Date(item.liveEndAt))}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{ color: "#8C8C8C" }}
                                >
                                  {item.description}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={openDialog}
                            >
                              削除
                            </Button>
                          </Stack>
                          <ConfirmDialog
                            title="ライブ情報を削除"
                            description="ライブ情報を本当に削除してもよろしいでしょうか？"
                            onClickExecute={() => {
                              void onClickLiveDelete(item.id ?? "");
                              closeDialog();
                            }}
                          />
                        </Container>
                      ))
                    ) : (
                      <div>ライブ情報が存在していません。</div>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="archives">
                <Grid container justifyContent="end" alignItems="end">
                  <Grid item xs={12} my={0}>
                    {state.archives.length > 0 ? (
                      state.archives.map((item) => (
                        <Container
                          key={item.id}
                          sx={{
                            width: "100%",
                            borderRadius: "4px",
                            borderColor: "#8C8C8C",
                            border: 1,
                            p: "8px",
                            my: "8px",
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              direction="row"
                              justifyContent="start"
                              alignItems="center"
                              spacing={4}
                            >
                              <Box>
                                <Avatar
                                  variant="rounded"
                                  sx={{
                                    bgcolor: "grey",
                                    width: 96,
                                    height: 56,
                                  }}
                                  alt={item.title}
                                  src={item.thumbnailUrl}
                                >
                                  No Image
                                </Avatar>
                              </Box>

                              <Stack
                                direction="column"
                                justifyContent="start"
                                alignItems="start"
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ color: "#8C8C8C" }}
                                >
                                  {item.title}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{ color: "#8C8C8C" }}
                                >
                                  {item.description}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={openDialog}
                            >
                              削除
                            </Button>
                          </Stack>
                          <ConfirmDialog
                            title="アーカイブ情報を削除"
                            description="アーカイブ情報を本当に削除してもよろしいでしょうか？"
                            onClickExecute={() => {
                              void onClickArchiveDelete(item.id ?? "");
                              closeDialog();
                            }}
                          />
                        </Container>
                      ))
                    ) : (
                      <div>アーカイブ情報が存在していません。</div>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="schools">
                <Grid container justifyContent="end" alignItems="end">
                  <Grid item xs={12} my={0}>
                    {state.schools.length > 0 ? (
                      state.schools.map((item) => (
                        <Container
                          key={item.id}
                          sx={{
                            width: "100%",
                            borderRadius: "4px",
                            borderColor: "#8C8C8C",
                            border: 1,
                            p: "8px",
                            my: "8px",
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Stack
                              direction="row"
                              justifyContent="start"
                              alignItems="center"
                              spacing={4}
                            >
                              <Box>
                                <Avatar
                                  sx={{
                                    bgcolor: "grey",
                                    width: 56,
                                    height: 56,
                                  }}
                                  alt={item.name}
                                  src={item.thumbnailUrl}
                                />
                              </Box>
                              <Stack
                                direction="column"
                                justifyContent="start"
                                alignItems="start"
                              >
                                <Box>
                                  <Typography
                                    variant="body1"
                                    sx={{ color: "#8C8C8C" }}
                                  >
                                    {item.name}
                                  </Typography>
                                </Box>
                                <Typography
                                  variant="caption"
                                  sx={{ color: "#8C8C8C" }}
                                >
                                  {item.code}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{ color: "#8C8C8C" }}
                                >
                                  {item.description}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={openDialog}
                            >
                              削除
                            </Button>
                          </Stack>
                          <ConfirmDialog
                            title="学校情報を削除"
                            description="学校情報を本当に削除してもよろしいでしょうか？"
                            onClickExecute={() => {
                              void onClickSchoolDelete(item.id ?? "");
                              closeDialog();
                            }}
                          />
                        </Container>
                      ))
                    ) : (
                      <div>学校情報が存在していません。</div>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
        </Stack>
      </Container>
    </PageBase>
  );
};

export default AgentUserDetailPageLayout;
