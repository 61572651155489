import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { type FC, useMemo, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export type InputFormProps = {
  /** ラベル文字列 */
  label: string;
  /** 必須ラベルかどうか */
  isLabelRequired?: boolean;
  /** 必須項目かどうか */
  required?: boolean;
  /** 値が変化した時のコールバック */
  onChange?: (txt: string) => void;
  /** エラー文字列 */
  error?: string | undefined;
  /** 複数行対応かどうか */
  multiline?: boolean;
  /** 最小表示行数 */
  minLines?: number | undefined;
  /** 最大表示行数 */
  maxLines?: number | undefined;
  /** パスワードマスク機能を利用するかどうか */
  password?: boolean;
  /** 入力欄タイプ */
  type?: string;
  /** 現在値 */
  value?: string;
  /** 単位等、入力文字の後ろにつけたいもの */
  endAdornmentStr?: string;
  /** 入力文字の始めにつけたいもの */
  startAdornmentStr?: string;
  /** Disabledかどうか */
  disabled?: boolean;
};

/**
 * テキスト入力フォームコンポーネント
 */
const InputForm: FC<InputFormProps> = ({
  label,
  isLabelRequired = false,
  required = false,
  onChange = undefined,
  error = undefined,
  multiline = false,
  minLines = undefined,
  maxLines = undefined,
  password = false,
  type = undefined,
  value = undefined,
  endAdornmentStr = undefined,
  startAdornmentStr = undefined,
  disabled = false,
}) => {
  const [passwordShowState, setPasswordShowState] = useState(!password);

  const handleClickShowPassword = () => {
    setPasswordShowState((prev) => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const currentType = useMemo(() => {
    if (type) {
      return type;
    }
    return passwordShowState ? "text" : "password";
  }, [passwordShowState, type]);

  const endAdornment = useMemo(() => {
    if (password) {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {passwordShowState ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      );
    }
    if (endAdornmentStr) {
      return <InputAdornment position="end">{endAdornmentStr}</InputAdornment>;
    }
    return null;
  }, [endAdornmentStr, password, passwordShowState]);

  const startAdornment = useMemo(() => {
    if (startAdornmentStr) {
      return (
        <InputAdornment position="start">{startAdornmentStr}</InputAdornment>
      );
    }
    return null;
  }, [startAdornmentStr]);

  return (
    <FormControl fullWidth error={!!error}>
      <Typography variant="subtitle1" textAlign="start">
        {label}
        {isLabelRequired ? (
          <span
            style={{
              color: "red",
              paddingLeft: "8px",
            }}
          >
            必須
          </span>
        ) : null}
      </Typography>
      <OutlinedInput
        required={required}
        id="outlined-adornment-amount"
        type={currentType}
        multiline={multiline}
        minRows={minLines}
        maxRows={maxLines}
        endAdornment={endAdornment}
        startAdornment={startAdornment}
        disabled={disabled}
        value={value}
        onChange={(e) =>
          onChange ? onChange(e.target.value ?? "") : undefined
        }
      />
      <FormHelperText component="div" error={!!error}>
        <Typography variant="caption" style={{ color: error ? "red" : "grey" }}>
          {error}
        </Typography>
      </FormHelperText>
    </FormControl>
  );
};

export default InputForm;
