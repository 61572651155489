import { atom } from "recoil";
import { ILive } from "../../interfaces/ILive";

export type LiveDetailPageState = {
  // ライブ情報
  live: ILive | undefined;
};

const initialState: LiveDetailPageState = {
  live: undefined,
};

const liveDetailPageStateAtom = atom({
  key: "LiveDetailPageState",
  default: initialState,
});

export default liveDetailPageStateAtom;
