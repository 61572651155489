/**
 * ランダムな指定文字数のテキストを発行
 */
export const ramdomText = (len: number): string => {
  const str = "abcdefghijklmnopqrstuvwxyz0123456789";
  const strLen = str.length;
  let result = "";

  for (let i = 0; i < len; i += 1) {
    result += str[Math.floor(Math.random() * strLen)];
  }
  return result;
};

export const isMovieJudge = (file: File): boolean => {
  const allowExtensions = ".(mp4|avi|mov|webm|flv)$";
  if (!file.name.match(allowExtensions)) {
    return false;
  }
  return true;
};
