import {
  updateDoc,
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  setDoc,
  QuerySnapshot,
  onSnapshot,
  where,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { IAgentUser, agentUserConverter } from "../interfaces/IAgentUser";

/**
 * 新規代理店ユーザーの作成する。
 */
export const createAgentUser = async (agentUser: IAgentUser, uid: string) => {
  const docRef = doc(firestore, "agent_users", uid).withConverter(
    agentUserConverter
  );
  await setDoc(docRef, agentUser);
};

/**
 * 指定したAgentUser IDの代理店ユーザー情報を更新する。
 */
export const updateAgentUser = async (
  agentUserId: string,
  agentUser: IAgentUser
) => {
  const docRef = doc(firestore, "agent_users", agentUserId).withConverter(
    agentUserConverter
  );
  return updateDoc(docRef, agentUser);
};

/**
 * 指定したAgentUser IDの代理店ユーザー情報を取得する。
 */
export const getAgentUserById = async (
  agentUserId: string
): Promise<IAgentUser | null> => {
  if (agentUserId === "") {
    return null;
  }
  const docRef = doc(firestore, "agent_users", agentUserId).withConverter(
    agentUserConverter
  );
  const snapshot = await getDoc(docRef);
  if (!snapshot.exists()) {
    return null;
  }
  return snapshot.data();
};

/**
 * 指定したAgentUser IDの代理店ユーザー情報を論理削除する。
 */
export const deleteAgentUser = async (agentUserId: string) => {
  const agentUser = await getAgentUserById(agentUserId);
  if (agentUser == null) {
    return null;
  }
  agentUser.isDeleted = true;
  const docRef = doc(firestore, "agent_users", agentUserId).withConverter(
    agentUserConverter
  );
  return updateDoc(docRef, agentUser);
};

/**
 * すべての代理店ユーザー情報をStreamに取得する。
 */
export const onSnapshotAgentUsers = (
  onChange: (snapshot: QuerySnapshot<IAgentUser>) => void
) => {
  const agentUsersCol = collection(firestore, "agent_users").withConverter(
    agentUserConverter
  );
  const q = query(
    agentUsersCol,
    where("isDeleted", "==", false),
    orderBy("createdAt")
  );
  const sub = onSnapshot(q, (snapshot) => {
    onChange(snapshot);
  });
  return sub;
};
